var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"is-flex is-justify-content-center is-align-items-center is-mid-height"},[_c('div',{staticClass:"card main-container p-4"},[_c('h2',{staticClass:"has-text-centered mb-3"},[_vm._v(_vm._s(_vm.$t('change_password')))]),_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('current_password'),"message":_setup.currentPasswordError ? _setup.currentPasswordErrorMessage : '',"type":_setup.currentPasswordError ? 'is-danger' : ''}},[_c('b-input',{attrs:{"expanded":"","type":"password","icon":"lock","password-reveal":""},on:{"input":() => 
                {
                  _setup.currentPasswordError = false;
                  if(_setup.newPasswordErrorMessage === _setup.t('new_password_same_as_current_password')) {
                    _setup.newPasswordError = false;
                  }
                }},model:{value:(_setup.currentPassword),callback:function ($$v) {_setup.currentPassword=$$v},expression:"currentPassword"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('new_password'),"message":_setup.newPasswordError ? _setup.newPasswordErrorMessage :   _vm.$t('password_strength_error_message'),"type":_setup.newPasswordError ? 'is-danger' : ''}},[_c('b-input',{attrs:{"expanded":"","type":"password","icon":"lock","password-reveal":""},on:{"input":() => {_setup.newPasswordError = false; _setup.confirmPasswordError = false;}},model:{value:(_setup.newPassword),callback:function ($$v) {_setup.newPassword=$$v},expression:"newPassword"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('confirm_password'),"message":_setup.confirmPasswordError ? _setup.confirmPasswordErrorMessage : '',"type":_setup.confirmPasswordError ? 'is-danger' : ''}},[_c('b-input',{attrs:{"expanded":"","type":"password","icon":"lock","password-reveal":""},on:{"input":() => {_setup.newPasswordError = false; _setup.confirmPasswordError = false;}},model:{value:(_setup.confirmNewPassword),callback:function ($$v) {_setup.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)],1)]),_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('router-link',{staticClass:"has-text-weight-normal has-text-primary is-underlined",attrs:{"to":"/profile","tag":"a"}},[_vm._v(_vm._s(_vm.$t('cancel_button')))]),_c('b-button',{staticClass:"button is-uppercase",attrs:{"type":"is-primary"},on:{"click":_setup.submitNewPassword}},[_vm._v(_vm._s(_vm.$t('save_button')))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { SnackbarProgrammatic as Snackbar } from "buefy";
import moment from "moment";
import Pessary from  "../models/Pessary"
import otherPessaryData from "../data/otherPessary.json"
import pessaryData from "../data/pessary.json"
import i18n from "../i18n";
import PatientForm from "@/models/PatientForm";
import VueRouter from "vue-router";


export class Utils {
  /**
   * Displays a success message.
   *
   * @param message Message to display on the toast.
   * @param duration Duration of the toast.
   */
  static toastSuccess(message: string, duration: number = 5000) {
    Snackbar.open({
      message: `<div class="message-icon-success"></div><div><h3>${i18n.global.t('success')}</h3><span>${message}</span></div><div></div>`,
      duration,
      type: "is-success",
      position: "is-top",
      actionText: "✕",
      queue: true,
      container: '#message-container'
    });
  }

  /**
   * Displays a warning message.
   *
   * @param message Message to display on the toast.
   * @param duration Duration of the toast.
   */
  static toastWarning(message: string, duration: number = 5000) {
    Snackbar.open({
      message: `<div class="message-icon-warning"></div><div><h3>${i18n.global.t('warning')}</h3><span>${message}</span></div><div></div>`,
      duration,
      type: "is-warning",
      position: "is-top",
      actionText: "✕",
      queue: true,
      container: '#message-container'
    });
  }

  /**
   * Displays an error message.
   *
   * @param message Message to display on the toast.
   * @param duration Duration of the toast.
   */
  static toastError(message: string, duration: number = 5000) {
    Snackbar.open({
      message: `<div class="message-icon-error"></div><div><h3>${i18n.global.t('error')}</h3><span>${message}</span></div><div></div>`,
      duration,
      type: "is-danger",
      position: "is-top",
      actionText: "✕",
      queue: true,
      container: '#message-container'
    });
  }

  /**
   * Checks the strength of a password.
   *
   * @param password Password to check.
   * @returns boolean depending if the password passes the check.
   */
  static verifyPasswordStrength(password: string) {
    const regexExpression =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    return regexExpression.test(password);
  }

  /**
   * Converts centimeters to inches rounded to the nearest whole number.
   *
   * @param value The amount in centimeters.
   * @returns The equivalent amount in inches rounded to the nearest whole number.
   */
  static convertCmToInches(value) {
    return parseFloat((value / 2.54).toFixed(2));
  }

  /**
   * Converts inches to centimeters rounded to the nearest whole number.
   *
   * @param value The amount in inches.
   * @returns The equivalent amount in centimeters rounded to the nearest whole number.
   */
  static convertInchesToCm(value) {
    return parseFloat((value * 2.54).toFixed(2));
  }

  /**
   * Converts kilograms to pounds rounded to the nearest tenths.
   *
   * @param value The amount in kilograms.
   * @returns The equivalent amount in pounds rounded to the nearest tenths.
   */
  static convertKgToLbs(value) {
    return parseFloat((value * 2.2046).toFixed(2));
  }

  /**
   * Converts pounds to kilograms rounded to the nearest tenths.
   *
   * @param value The amount in pounds.
   * @returns The equivalent amount in kilograms rounded to the nearest tenths.
   */
  static convertLbsToKg(value) {
    return parseFloat((value / 2.2046).toFixed(2));
  }

  /**
   * Converts cups to milliliters rounded to the nearest whole number.
   *
   * @param value The amount in cups.
   * @returns The equivalent amount in milliliters rounded to the nearest whole number.
   */
  static convertCupToMl(value) {
    return Math.round(value * 236.588);
  }

  /**
   * Converts ml to milliliters rounded to the nearest whole number.
   *
   * @param value The amount in ml.
   * @returns The equivalent amount in milliliters rounded to the nearest containing one decimal.
   */
  static convertMlToCup(value) {
    return parseFloat((value / 236.588).toFixed(1));
  }

  /**
     * Format the date after retrieving the date from backend. 
     * add 1 to month to fit JS requirement for front end month calculation which starts from 1
     * @param date to be formatted
     * @returns formatted date string
     */
  static formatDate(date: Date) {
    if (date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const monthString = month.toString().padStart(2, "0");
      const day = date.getDate();
      const dayString = day.toString().padStart(2, "0");
      const displayingDate = [year, monthString, dayString].join("-");
      return displayingDate;
    }
  }
  /**
   * Determine how much time in days passed since the start of the bladder and return it to calculate 24-hour average appropriately
   * @param endTime of the bladder diary
   * @param startTime of the bladder diary
   * @returns 
   */
  static calculateTimePassed(endTime: string, startTime: string) {
    if (new Date() > new Date(endTime)) {
      return moment(endTime).diff(moment(startTime), 'days', true)
    } else {
      const timePassed = moment().diff(moment(startTime), 'days', true)
      return timePassed <= 1 ? 1 : timePassed;
    }
  }

  /**
    * Retrieves client side language cookie value by name
    * @returns language cookie value or default
    */
  static getLanguageCookieValue(): string {
    const languageCookieName = "COSM_PatientApp_Language";
    const documentCookies = document.cookie.split('; ').reduce((accumulator, current) => {
      const keyVal = current.split('=');
      accumulator[keyVal[0]] = keyVal[1];
      return accumulator;
    }, {});
    return documentCookies[languageCookieName] ? documentCookies[languageCookieName] : 'en';
  }

  /**
   * Validates Key presses on number inputs to prevent users from entering alpha character and operators
   * Prevents default action if the character is not numeric
   */
  static validNumberInput(event) {
    if (event.data != null && (event.data.charCodeAt(0) < 46 || event.data.charCodeAt(0) > 57 || event.data.charCodeAt(0) == 47 || (event.data.charCodeAt(0) == 46 && event.target?.name != "weight"))) {
      // Prevents default action (Key Input)
      event.preventDefault()
    }
  }

  /**
  * Formats an existing string phone number to (xxx) xxx-xxxx
  */
  static formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  /**
   * Creates an embedded YouTube url in the format of: https://www.youtube.com/embed/xxxxxxxx" given a YouTube link in the format of: https://www.youtube.com/watch?v=xxxxxxxxxxx
   */
  static createEmbedYouTubeUrl(youtubeUrl) {
    let youTubeId;
    const youtubeEmbedConversionResult: { error: string, url: string } = {
      error: '',
      url: "https://www.youtube.com/embed/"
    }

    if (!youtubeUrl || youtubeUrl.toString().match(/&v/i)?.length || youtubeUrl.toString().match(/https/gi)?.length > 1) {
      youtubeEmbedConversionResult.error = "The provided video link is not a valid YouTube Video URL."
      return youtubeEmbedConversionResult
    }

    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = youtubeUrl.toString().match(regExp);

    if (match && match[7].length == 11) {
      youTubeId = match[7]
    } else {
      youtubeEmbedConversionResult.error = "The provided video link is not a valid YouTube Video URL."
    }

    youtubeEmbedConversionResult.url = youtubeEmbedConversionResult.url + youTubeId

    return youtubeEmbedConversionResult
  }

  // get image information including name and title
  static getImageInfo(productCode) {
    if (productCode) {
      switch (productCode.toUpperCase()) {
        case "GXX":
          return {
            name: "GXX_Gellhorn",
            title: `${i18n.global.t('p_products_name_gellhorn')}`
          }
        case "CXX":
          return {
            name: "CXX_cube_with_cord",
            title: `${i18n.global.t('p_products_name_cube')}`
          }
        case "MXX":
          return {
            name: "MXX_Marland",
            title: `${i18n.global.t('p_products_name_marland_without_support')}`
          }
        case "MSX":
          return {
            name: "MSX_marland_with_support",
            title: `${i18n.global.t('p_products_name_marland_with_support')}`
          }
        case "RSK":
          return {
            name: "RSK_ring_with_support_and_knob",
            title: `${i18n.global.t('p_products_name_ring_knob_with_support')}`
          }
        case "RIK":
          return {
            name: "RIK_incontinence_ring",
            title: `${i18n.global.t('p_products_name_incontinence_ring')}`
          }
        case "RSX":
          return {
            name: "RSX_ring_with_support",
            title: `${i18n.global.t('p_products_name_ring_with_support')}`
          }
        case "RXK":
          return {
            name: "RXK_ring_with_knob",
            title: `${i18n.global.t('p_products_name_ring_knob_without_support')}`
          }
        case "RXX":
          return {
            name: "RXX_Ring",
            title: `${i18n.global.t('p_products_name_ring_without_support')}`
          }
      }
    }
    return undefined
  }

  static getFormIdByTitle(patientFormData: PatientForm[], formTitle: string) {
    return patientFormData.find(form => form.title === formTitle)?.id;
  }

  /**
  * Returns a string representing the measurments of a given pessary in inches and milimeters
  */
  static getFormattedPessarySize(pessary) {
    if (!pessary.id){
      return;
    }

    if (pessary.manufacturerName === otherPessaryData.manufacturer || pessary.manufacturerName === "Other") {
      return pessary.size?.toString()
    }

    const pessarySize = pessaryData.manufacturers.manufacturer
    .find(m => m.name === pessary.manufacturerName)?.types
    .find(t => t.name === pessary.pessaryType)?.sizes
    .find(s => s.size === pessary.size)

    return `${pessarySize?.size} (${i18n.global.n(pessarySize?.measurement.imperial!)}${i18n.global.t('unit_inch')}, ${(pessarySize?.measurement.metric)} ${i18n.global.t('unit_mm')})`
  }

  /**
  * Returns a string representing the measurments of a given pessary type and sizeOption in inches and milimeters
  */
  static getFormattedPessarySizeOptions(pessary, sizeOption, isFollowup = false) {
    if (isFollowup && (!pessary.id || pessary.size === undefined || sizeOption === null)) {
      return;
    }

    if (pessary.manufacturerName === otherPessaryData.manufacturer || pessary.manufacturerName === "Other") {
      return sizeOption;
    }

    const pessarySize = pessaryData.manufacturers.manufacturer
      .find(m => m.name === pessary.manufacturerName)?.types
      .find(t => t.name === pessary.pessaryType)?.sizes.find(s => s.size === sizeOption)
      
    return `${pessarySize?.size} (${i18n.global.n(pessarySize?.measurement.imperial!)}${i18n.global.t('unit_inch')}, ${(pessarySize?.measurement.metric)} ${i18n.global.t('unit_mm')})`
  }

  /**
   * Pushes event to GTM analytics by event name.
   * @param event_name event name to send - must match event name of configured custom event
   * @param parameter optional event parameter to send - must match configured event parameter name
   */
  static pushFormEventToAnalytics(event_name: string, parameterValue:string = '') {
    if (window?.dataLayer) {
      console.info("GA4 Initialized");
      window.dataLayer.push({
          'event': event_name,
          ...(parameterValue && {'form_name': parameterValue}),
      });
    } else {
      console.error("Error initializing GA4");
    }
  }
}

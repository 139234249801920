import { render, staticRenderFns } from "./PelvicSurgeries.vue?vue&type=template&id=554b7733&scoped=true&"
import script from "./PelvicSurgeries.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PelvicSurgeries.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PelvicSurgeries.vue?vue&type=style&index=0&id=554b7733&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554b7733",
  null
  
)

export default component.exports
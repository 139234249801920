import Pfiq7Section from "@/models/Pfiq7Section";
import { ApiService } from "@/services/core/apiService";

/**
 * Represents a Symptom & Impact Report service.
 */
class SymptomAndImpactReportService extends ApiService<Pfiq7Section> {
  resourcePath: string = "SymptomAndImpactReport";
}

export default new SymptomAndImpactReportService();







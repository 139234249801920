import { defineStore, StoreDefinition } from "pinia";
import SymptomAndImpactForm from "@/models/SymptomAndImpactForm";

export const useSymptomAndImpactStore: StoreDefinition = defineStore("symptomAndImpact", {
  state: () => {
    return {
      formData: new SymptomAndImpactForm()
    };
  },
});

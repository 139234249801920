import { render, staticRenderFns } from "./HealthInfo.vue?vue&type=template&id=15f878aa&scoped=true&"
import script from "./HealthInfo.vue?vue&type=script&setup=true&lang=ts&"
export * from "./HealthInfo.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./HealthInfo.vue?vue&type=style&index=0&id=15f878aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f878aa",
  null
  
)

export default component.exports
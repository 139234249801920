import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { useUserStore } from "@/store/userStore";
import pinia from "@/plugins/pinia";
import i18n from "@/i18n";
import formsRoutes from "@/router/modules/forms.routes";
import registrationRoutes from "@/router/modules/registration.routes";
import homeRoutes from "@/router/modules/home.routes";
//import diaryRoutes from "@/router/modules/diary.routes";
import errorRoutes from "@/router/modules/error.routes";
import educationRoutes from "@/router/modules/education.routes";
import profileRoutes from "@/router/modules/profile.routes";
import utilRoutes from "@/router/modules/utils.routes";
import healthInformationRoutes from "@/router/modules/healthInformation.routes";
import { Utils } from "@/utils";
import { useDemographicStore } from "@/store/demographicStore";
import demographicService from "@/services/resources/demographicService";
import diagnosisRoutes from "@/router/modules/diagnosis.routes";
import pessariesRoutes from "@/router/modules/pessaries.routes";
import issuesLogRoutes from "@/router/modules/issuesLog.routes";
import PatientFormService from "@/services/resources/PatientFormService";
import { FormsConstants } from "@/constants/forms";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...homeRoutes,
  ...formsRoutes,
  ...registrationRoutes,
  ...errorRoutes,
  ...educationRoutes,
  ...profileRoutes,
  ...healthInformationRoutes,
  ...diagnosisRoutes,
  ...pessariesRoutes,
  ...issuesLogRoutes,
  //...diaryRoutes,
  ...utilRoutes,
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const unauthenticatedRoutes = [
    "forgot password",
    "reset password",
    "accept invitation",
    "confirm email",
    "confirm phone number",
    "home",
    "not found",
    "bad request",
    "server error",
    "privacy policy",
    "terms of service",
  ];

  const registrationRoutes = [
    "accept invitation",
    "intake assessment",
    "intake home",
    "introduction",
    "impact of symptoms",
    "symptoms",
    "my health",
    "pelvic health survey",
    "bad request",
    "server error",
    "privacy policy",
    "terms of service",
    "welcome page",
  ];

  const isUserAccessingAuthenticatedRoute = !unauthenticatedRoutes.includes(
    to.name?.toLowerCase()!
  );
  const userStore = useUserStore(pinia);
  const demographicStore = useDemographicStore();

  // Use current or parent route's locale key for the browser title, if available
  const titleLocaleKey =
    to.meta?.titleLocaleKey || to.matched[0].meta?.titleLocaleKey;
  if (titleLocaleKey) {
    document.title = i18n.global.t(titleLocaleKey);
  }

  if (
    !userStore.isLoggedIn &&
    !(await userStore.setUser()) &&
    isUserAccessingAuthenticatedRoute
  ) {
    userStore.$reset();

    if (document.cookie.length === 0) {
      i18n["locale"] = "en";
    } else {
      i18n["locale"] = Utils.getLanguageCookieValue();
    }
    next({ name: "Home", query: {returnUrl: to.fullPath} });
  }

  if (userStore.isLoggedIn && to.name?.includes("Welcome Page")) {
    if (document.cookie.length === 0) {
      i18n["locale"] = "en";
    } else {
      i18n["locale"] = Utils.getLanguageCookieValue();
    }

    if (userStore.user.privacyAcceptedAt != null) {
      return next({ name: "Home" });
    }

    return next();
  }

  if (userStore.isLoggedIn) {
    if (
      !to.name?.includes("Privacy Policy") &&
      !userStore.user.privacyAcceptedAt
    ) {
      router.push({ name: "Privacy Policy" });
    } else if (
      !to.name?.includes("Terms of Service") &&
      !userStore.user.termsAcceptedAt &&
      userStore.user.privacyAcceptedAt
    ) {
      router.push({ name: "Terms of Service" });
    } else {
      const response = await demographicService.query();
      demographicStore.isCompletedRegistration =
        response.data[0].isCompletedRegistration;

      if (
        !demographicStore.isCompletedRegistration &&
        !registrationRoutes.includes(to.name?.toLowerCase() as string)
      ) {
        const formsResponse = await PatientFormService.query();

        if (formsResponse.status === 200) {
          const intakeFormId = Utils.getFormIdByTitle(formsResponse.data, FormsConstants.INTAKE_ASSESSMENT_TITLE);
          router.push({ name: "Intake Home", query: { id: intakeFormId?.toString() } })
        } else {
          router.push("Not Found");
        }
      }
    }
  }

  if (document.cookie.length === 0) {
    i18n["locale"] = "en";
  } else {
    i18n["locale"] = Utils.getLanguageCookieValue();
  }

  next();
});

export default router;

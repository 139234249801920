import { ApiService } from "@/services/core/apiService";
import DemographicSubmission from "@/models/DemographicSubmission";

/**
 * Represents a Demographic service.
 */
class DemographicService extends ApiService<DemographicSubmission> {
  resourcePath: string = "demographic";
}

export default new DemographicService();

import Pfiq7Scale from "./Pfiq7Scale";
import SectionBase from "./SectionBase";

export default class Pfiq7Section extends SectionBase {
  id?: number;
  patientFormId?: number;
  sectionReplyId?: number;
  abilityToTravel: Pfiq7Scale = new Pfiq7Scale();
  emotionalHealth: Pfiq7Scale = new Pfiq7Scale();
  entertainmentActivities: Pfiq7Scale = new Pfiq7Scale();
  feelingFrustrated: Pfiq7Scale = new Pfiq7Scale();
  householdChores: Pfiq7Scale = new Pfiq7Scale();
  physicalActivities: Pfiq7Scale = new Pfiq7Scale();
  socialActivities: Pfiq7Scale = new Pfiq7Scale();
  completedAt?: Date;
  saveRequest?: boolean = false;
  uiq: number = 0;
  craiq: number = 0;
  popiq: number = 0;
  totalScore: number = 0;
}

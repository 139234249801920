import { render, staticRenderFns } from "./SectionActivityAndVaginalHealth.vue?vue&type=template&id=2617ba8a&scoped=true&"
import script from "./SectionActivityAndVaginalHealth.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SectionActivityAndVaginalHealth.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SectionActivityAndVaginalHealth.vue?vue&type=style&index=0&id=2617ba8a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2617ba8a",
  null
  
)

export default component.exports
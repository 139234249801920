import { ApiService } from "@/services/core/apiService";
import Patient from "@/models/Patient";


/**
 * Represents an Patient service.
 */
class PatientService extends ApiService<Patient> {
  resourcePath: string = "patient";

  // 👇 Example of overriding base .catch() error handler...
  // override get(id?: any): Promise<AxiosResponse<Patient, any>> {
  //   const url = [this.path, id].filter((p) => !!p).join("/");
  //   return this.axios.get<Patient>(url).catch(this.passthroughErrorHandler);
  // }
}

export default new PatientService();



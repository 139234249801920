import SectionBase from "./SectionBase";

export default class IntroductionSubmission extends SectionBase {
    id?: number;
    patientSectionId?: number;
    sectionReplyId?: number;
    height?: number | null;
    weight?: number | null;
    unit: string = "enum_metric";
    isEmailNotification : boolean = false;
    isSmsNotification: boolean = false;
    preferredName?: string;
    firstName?: string;
    lastName?: string;
    birthDate?:Date;
    updatedByUser?: string;
    updatedAt?: string;
    completedAt?: Date;
    saveRequest?: boolean = false;
    isCompleted: boolean = false;
  }
  
import SectionBase from "./SectionBase";

export default class Pfdi20Section extends SectionBase {
  patientFormId?: number;
  patientSectionId?: number;
  frequentUrination?: number;
  leakageUrgency?: number;
  leakageCoughing?: number;
  leakageDrops?: number;
  difficultyEmptyingBladder?: number;
  experiencePainAbdomen?: number;
  strainBowelMovement?: number;
  notEmptiedBowels?: number;
  loseWellFormed?: number;
  loseLoose?: number;
  loseGas?: number;
  painPassStool?: number;
  urgencyBowelMovement?: number;
  bowelPassThrough?: number;
  pressureAbdomen?: number;
  heavinessDullness?: number;
  bulgeFallingOut?: number;
  pushVaginaRectum?: number;
  incompleteEmptying?: number;
  pushCompleteUrination?: number;
  udiScaleScore?: number = 0;
  cradiScaleScore?: number = 0;
  popdiScaleScore?: number = 0;
  summaryScore?: number = 0;
  sectionReplyId?: number;
  saveRequest?: boolean = false;
  completedAt?: Date;
}

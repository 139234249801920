import { ApiService } from "@/services/core/apiService";
import Pessary from "@/models/Pessary";

/**
 * Represents a Pessary service.
 */
class PessaryService extends ApiService<Pessary> {
  resourcePath: string = "Pessary";
}

export default new PessaryService();

import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=7b89b7bd&scoped=true&"
import script from "./ChangePassword.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ChangePassword.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ChangePassword.vue?vue&type=style&index=0&id=7b89b7bd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b89b7bd",
  null
  
)

export default component.exports
import IntakeSubmission from "@/models/IntakeSubmission";
import { ApiService } from "@/services/core/apiService";

/**
 * Represents an Intake service.
 */
class IntakeService extends ApiService<IntakeSubmission> {
  resourcePath: string = "Intake";
}

export default new IntakeService();




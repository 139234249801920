import PessaryHistory from "./PessaryHistory";

export default class Pessary {
  id?: number;
  serialNumber?: string;
  pessaryType?: string;
  manufacturerName?: string;
  currentlyWearing?: boolean;
  size?: number;
  dimensions?: string;
  fittedAt?: string;
  discontinuedAt?: string;
  yearStarted?: number;
  yearStopped?: number;
  monthStarted?: number;
  monthStopped?: number;
  pessaryHistory: PessaryHistory = new PessaryHistory();
  productCode?: string;
  parameters?: string;
}

<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center">
    <!-- Question 1 required-->
    <div>
      <DropdownQuestion :question="$t(uiData[0].localeKey)" :options="uiData[0].options" :logicalId="uiData[0].logicalId"
        :readOnly="props.readOnly" :hasError="dailyActivityMessage?.length > 0">
      </DropdownQuestion>
      <p class="error-message" v-if="dailyActivityMessage && !props.readOnly"><i class="mdi mdi-alert-circle-outline"></i> {{
        dailyActivityMessage }}</p>
    </div>
    <!-- Question 2 required -->
    <div>
      <DropdownQuestion :question="$t(uiData[1].localeKey)" :options="uiData[1].options" :logicalId="uiData[1].logicalId"
        :readOnly="props.readOnly" :hasError="oftenExerciseMessage?.length > 0">
      </DropdownQuestion>
      <p class="error-message" v-if="oftenExerciseMessage && !props.readOnly"><i class="mdi mdi-alert-circle-outline"></i> {{
        oftenExerciseMessage }}</p>
      <!-- Question 3 required-->
      <div v-if="doExercise" class="mt-4">
        <DropdownQuestion :question="$t(uiData[2].localeKey)" :options="uiData[2].options"
          :logicalId="uiData[2].logicalId" :readOnly="props.readOnly" :hasError="typeExerciseMessage?.length > 0">
        </DropdownQuestion>
        <p class="error-message" v-if="typeExerciseMessage && !props.readOnly"><i class="mdi mdi-alert-circle-outline"></i> {{
          typeExerciseMessage }}</p>
      </div>
    </div>

    <!-- Question 4 Not required-->
    <div>
      <DropdownQuestion :question="$t(uiData[3].localeKey)" :options="uiData[3].options" :logicalId="uiData[3].logicalId"
        :readOnly="props.readOnly" :optional="true" :position="topRight">
      </DropdownQuestion>
    </div>
    <!-- Question 5 required-->
    <div v-if="showIntercourseQuestion">
      <div>
        <p class="my-2 question-style">
          {{ $t(uiData[4].localeKey) }}
          <span class="has-text-danger">*</span>
        </p>
        <b-field>
          <b-radio :class="{ 'error-radio': sexuallyIntercourseMessage }"
            v-model="onboardingStore.formData.pelvicHealthSurvey.sexualIntercourse" :native-value="true" @input="answerSelectionUpdated"
            :disabled="props.readOnly">{{ $t(uiData[4].options.at(0).localeKey) }}</b-radio>
        </b-field>
        <b-field>
          <b-radio :class="{ 'error-radio': sexuallyIntercourseMessage }" @input="answerSelectionUpdated"
            v-model="onboardingStore.formData.pelvicHealthSurvey.sexualIntercourse" :native-value="false"
            :disabled="props.readOnly">{{ $t(uiData[4].options.at(1).localeKey)
            }}</b-radio>
        </b-field>
        <p class="error-message" v-if="sexuallyIntercourseMessage && !props.readOnly"><i class="mdi mdi-alert-circle-outline"></i> {{
          sexuallyIntercourseMessage }}</p>
      </div>
    </div>
  </div>
</template>
  
<script setup="ts">
import { ref, computed, watch } from "vue";
import activityAndExercise from "@/data/activityandexercise.json";
import DropdownQuestion from "./DropdownQuestion.vue";
import { useOnboardingStore } from "@/store/onboardingStore";
import { useI18n } from 'vue-i18n-bridge'

const { t } = useI18n();
const onboardingStore = useOnboardingStore();
let uiData = ref(activityAndExercise);
let dailyActivityMessage = ref("");
let oftenExerciseMessage = ref("");
let typeExerciseMessage = ref("")
let sexuallyIntercourseMessage = ref("")
let topRight = ref("is-top-right");

const props = defineProps({
  readOnly: Boolean
});

// computed property
let doExercise = computed(() => {
  return (
    onboardingStore.formData.pelvicHealthSurvey.oftenExercise != "enum_no_exercise" && onboardingStore.formData.pelvicHealthSurvey.oftenExercise != undefined);
});

let showIntercourseQuestion = computed(() => {
  return onboardingStore.formData.pelvicHealthSurvey.sexuallyActive === uiData.value[3].options.at(1).logicalId || onboardingStore.formData.pelvicHealthSurvey.sexuallyActive === uiData.value[3].options.at(2).logicalId
})

function answerSelectionUpdated() {
  onboardingStore.formData.pelvicHealthSurvey.requiresSave = true;
}

watch(() => onboardingStore.formData.pelvicHealthSurvey.dailyActivities, () => {
  if (onboardingStore.formData.pelvicHealthSurvey.dailyActivities != undefined) {
    dailyActivityMessage.value = ""
  }
})

watch(() => onboardingStore.formData.pelvicHealthSurvey.sexuallyActive, () => {
  sexuallyIntercourseMessage.value = ""
  if (onboardingStore.formData.pelvicHealthSurvey.sexualIntercourse != undefined) {
    onboardingStore.formData.pelvicHealthSurvey.sexualIntercourse = undefined
  }
})

watch(() => onboardingStore.formData.pelvicHealthSurvey.sexualIntercourse, () => {
  sexuallyIntercourseMessage.value = ""
})

watch(() => onboardingStore.formData.pelvicHealthSurvey.oftenExercise, () => {
  if (onboardingStore.formData.pelvicHealthSurvey.oftenExercise != undefined) {
    oftenExerciseMessage.value = ""
    if (onboardingStore.formData.pelvicHealthSurvey.typeExercise != undefined) {
      onboardingStore.formData.pelvicHealthSurvey.typeExercise = undefined
    }
  }
})

watch(() => onboardingStore.formData.pelvicHealthSurvey.typeExercise, () => {
  if (onboardingStore.formData.pelvicHealthSurvey.typeExercise != undefined) {
    typeExerciseMessage.value = ""
  }
})

function isActivityAndExerciseValid() {
  if (onboardingStore.formData.pelvicHealthSurvey.dailyActivities === undefined) {
    dailyActivityMessage.value = t("required_question")
  }

  if (onboardingStore.formData.pelvicHealthSurvey.oftenExercise === undefined) {
    oftenExerciseMessage.value = t('required_question')
  }

  if (onboardingStore.formData.pelvicHealthSurvey.oftenExercise !== "enum_no_exercise" && onboardingStore.formData.pelvicHealthSurvey.oftenExercise != undefined) {
    if (onboardingStore.formData.pelvicHealthSurvey.typeExercise === undefined) {
      typeExerciseMessage.value = t('required_question')
    }
  }

  if (onboardingStore.formData.pelvicHealthSurvey.sexuallyActive === uiData.value[3].options.at(1).logicalId || onboardingStore.formData.pelvicHealthSurvey.sexuallyActive === uiData.value[3].options.at(2).logicalId) {
    if (onboardingStore.formData.pelvicHealthSurvey.sexualIntercourse === undefined) {
      sexuallyIntercourseMessage.value = t('required_question')
    }
  }

  if (dailyActivityMessage.value.length || oftenExerciseMessage.value.length || sexuallyIntercourseMessage.value.length) {
    return false
  }

  if (onboardingStore.formData.pelvicHealthSurvey.oftenExercise !== "enum_no_exercise") {
    if (typeExerciseMessage.value.length) {
      return false
    }
  }

  return true

}
defineExpose({ isActivityAndExerciseValid })

</script>
<style scoped>
.question-style {
  color: var(--primary-black, #24202F);
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.error-message {
  color: var(--alert-error, #D32F2F);
  font-size: 12px;
  font-weight: 400;
}
</style>
  
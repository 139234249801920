import { render, staticRenderFns } from "./Pessaries.vue?vue&type=template&id=6fc6b79f&scoped=true&"
import script from "./Pessaries.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Pessaries.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Pessaries.vue?vue&type=style&index=0&id=6fc6b79f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc6b79f",
  null
  
)

export default component.exports
import Vue from "vue";

import { createPinia, Pinia, PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin); // Only needed for Vue 2.7, remove this for Vue 3+

const pinia: Pinia = createPinia();

// Pinia plugins go here...

export default pinia;

import { render, staticRenderFns } from "./ModalBase.vue?vue&type=template&id=83f48d8e&scoped=true&"
import script from "./ModalBase.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ModalBase.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ModalBase.vue?vue&type=style&index=0&id=83f48d8e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83f48d8e",
  null
  
)

export default component.exports
import { ApiService } from "@/services/core/apiService";

/**
 * Represents an Pfiq7 service.
 */
class SymptomAndImpact extends ApiService<SymptomAndImpact> {
  resourcePath: string = "symptomAndImpact";
}

export const symptomAndImpactService = new SymptomAndImpact();

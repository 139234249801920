<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center">
    <!-- Question top 3 features not required-->
    <p class="mt-2 mb-4 question-style">
      {{ $t(uiData[0].localeKey) }}
    </p>
    <div v-if="onboardingStore.formData.pelvicHealthSurvey.top3Features.length === 3"
      class="columns is-mobile information-style">
      <div class="column is-2-mobile is-2-tablet small-column" v-if="!props.readOnly">
        <b-icon icon="alert-circle-outline" />
      </div>
      <div class="column is-11" v-if="!props.readOnly">
        <p>{{ $t("intake_top_3_features_info") }}</p>
      </div>
    </div>
    <div :class="onboardingStore.formData.pelvicHealthSurvey.top3Features.length < 3 ? 'mt-3' : ''">
      <ul>
        <li v-for="item in uiData[0].options" :key="item.localeKey" class="mb-3" :disabled="props.readOnly">
          <b-checkbox v-model="onboardingStore.formData.pelvicHealthSurvey.top3Features" :native-value="item.localeKey"
            :disabled="(onboardingStore.formData.pelvicHealthSurvey.top3Features.length === 3 &&
              onboardingStore.formData.pelvicHealthSurvey.top3Features.indexOf(item.localeKey) === -1) || props.readOnly"
            @input="answerSelectionUpdated">
            {{ $t(item.localeKey) }}
          </b-checkbox>
        </li>
      </ul>
      <p class="mt-4 question-style"
        v-if="onboardingStore.formData.pelvicHealthSurvey.top3Features.length <= 3 && onboardingStore.formData.pelvicHealthSurvey.top3Features.includes('feature_other')">
        {{
          $t("other_please_specify") }} <span class="has-text-danger">*</span></p>
      <b-input
        v-if="onboardingStore.formData.pelvicHealthSurvey.top3Features.length <= 3 && onboardingStore.formData.pelvicHealthSurvey.top3Features.includes('feature_other')"
        v-model="onboardingStore.formData.pelvicHealthSurvey.otherFeatures" :use-html5-validation="false"
        :class="showErrorMessage ? 'error-border' : ''" :has-counter="false" :disabled="props.readOnly" maxlength="1024"
        @input="answerSelectionUpdated" />
      <p class="error-message mt-2" v-if="showErrorMessage && !props.readOnly"><i
          class="mdi mdi-alert-circle-outline"></i> {{
            otherFeatureMessage }}</p>
    </div>
  </div>
</template>
    
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import pessaryDesign from "@/data/pessarydesign.json";
import { useOnboardingStore } from "@/store/onboardingStore";
import { useI18n } from 'vue-i18n-bridge'

const { t } = useI18n();
const onboardingStore = useOnboardingStore();
let uiData = ref(pessaryDesign);
let otherFeatureMessage = ref("")

onMounted(() => {
  if (onboardingStore.formData.pelvicHealthSurvey.top3Features === undefined) {
    onboardingStore.formData.pelvicHealthSurvey.top3Features = []
  }
})

const props = defineProps({
  readOnly: Boolean
});

const showErrorMessage = computed(() => {
  return otherFeatureMessage.value && onboardingStore.formData.pelvicHealthSurvey.otherFeatures === undefined
})

function answerSelectionUpdated() {
  onboardingStore.formData.pelvicHealthSurvey.requiresSave = true;
}
watch(() => onboardingStore.formData.pelvicHealthSurvey.top3Features.length, () => {

  // At least one option has been selected in top 3 features
  if (onboardingStore.formData.pelvicHealthSurvey.top3Features.length >= 1) {
    // if last selected option is 'I don't know', clear out all other options and keep 'I don't know'
    if (onboardingStore.formData.pelvicHealthSurvey.top3Features[onboardingStore.formData.pelvicHealthSurvey.top3Features.length - 1] === "feature_i_do_not_know") {
      onboardingStore.formData.pelvicHealthSurvey.top3Features = onboardingStore.formData.pelvicHealthSurvey.top3Features.filter(t => t === "feature_i_do_not_know")
      if (onboardingStore.formData.pelvicHealthSurvey.otherFeatures != undefined) {
        onboardingStore.formData.pelvicHealthSurvey.otherFeatures = undefined;
      }
      otherFeatureMessage.value = '';
    } else {
      // if first selected option is 'I don't know
      if (onboardingStore.formData.pelvicHealthSurvey.top3Features[0] === "feature_i_do_not_know") {
        onboardingStore.formData.pelvicHealthSurvey.top3Features = onboardingStore.formData.pelvicHealthSurvey.top3Features.filter(t => t != "feature_i_do_not_know")
        // if at least 1 option is left after filtering 
        if (onboardingStore.formData.pelvicHealthSurvey.top3Features.length >= 1) {
          checkIfFeatureOtherSelected(onboardingStore.formData.pelvicHealthSurvey.top3Features)
        } else { // if no option is left after filtering
          noOptionsSelected()
        }
      } else {
        checkIfFeatureOtherSelected(onboardingStore.formData.pelvicHealthSurvey.top3Features)
      }
    }
  } else { // no option has been selected
    noOptionsSelected()
  }
})

function checkIfFeatureOtherSelected(top3Features) {
  let tempArray = top3Features.filter((item) => item === "feature_other")
  if (tempArray.length <= 0) {
    if (onboardingStore.formData.pelvicHealthSurvey.otherFeatures != undefined) {
      onboardingStore.formData.pelvicHealthSurvey.otherFeatures = undefined;
    }
    otherFeatureMessage.value = ""
  }
}

function noOptionsSelected() {
  if (onboardingStore.formData.pelvicHealthSurvey.otherFeatures != undefined) {
    onboardingStore.formData.pelvicHealthSurvey.otherFeatures = undefined;
  }
  otherFeatureMessage.value = ""
}

// check if the form is valid 
function isPessaryDesignValid() {
  if (onboardingStore.formData.pelvicHealthSurvey.top3Features.includes("feature_other")) {
    if (onboardingStore.formData.pelvicHealthSurvey.otherFeatures === undefined || onboardingStore.formData.pelvicHealthSurvey.otherFeatures.trim() === '') {
      otherFeatureMessage.value = t('required_question')
      if (onboardingStore.formData.pelvicHealthSurvey.otherFeatures != undefined) {
        onboardingStore.formData.pelvicHealthSurvey.otherFeatures = undefined
      }
      return false
    }
  }
  return true
}

defineExpose({ isPessaryDesignValid })

</script>
<style lang="scss" scoped>
@use "@/assets/styles/colors.scss";

.question-style {
  color: var(--primary-black, #24202F);
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.error-message {
  color: var(--alert-error, #D32F2F);
  font-size: 12px;
  font-weight: 400;
}

.error-border ::v-deep input.input {
  border: 1px solid colors.$red;
}

.columns {
  margin-bottom: 0 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.info {
  color: var(--secondary-text-black, #57545D);
  /* Form fields/Helper text */
  font-family: Ubuntu;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 200% */
}
.information-style {
    color: var(--primary-black, #24202F);
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.small-column {
  width: 2% !important;
  margin-right: 8px;
  color: var(--primary-black, #24202F);
  font-family: Ubuntu;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 4px;
}
</style>
    
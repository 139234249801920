import { RouteConfig } from "vue-router";
import IssuesLog from "@/components/IssuesLog/IssuesLog.vue";
import GenericView from "@/views/GenericView.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/issue-log",
    component: GenericView,
    meta: {
      showNavBar: true,
      titleLocaleKey: "issue_log"
    },
    children: [
      {
        path: "",
        name: "Issue Log",
        component: IssuesLog,
      },
    ]
  },
];

export default routes;

import FollowUpSubmission from "@/models/FollowUpSubmission";
import { ApiService } from "@/services/core/apiService";

/**
 * Represents an Followup service.
 */
class FollowupService extends ApiService<FollowUpSubmission> {
    resourcePath: string = "followup";
  }
  
  export default new FollowupService();
import { Route } from "vue-router";

/**
 * Traverses the router.matched route tree in reverse (child to parent) looking for the specified meta router prop.
 * For example: If current route is a sub route that doesn't have a 'hideNavBar' prop set, but one of it's 
 * parents does have it set, the parents prop will apply.  Alternatively, if the current route has it set to false 
 * while it's parent has it set to true, the current child routes configuration will take precedence.
 * 
 * tldr; The child routes meta prop takes precedence
 * 
 * @author Colin Kent-Shepherd
 * @param route {Route} - route to traverse
 * @param propName {string} - the prop name to search for
 * @returns The value if found; Otherwise, undefined
 */

export function getRouteMetaProp(route: Route, propName: string) : any {
  const routesFromChildToParent = route.matched?.slice().reverse()
  const routeWithPrecedence = routesFromChildToParent?.find((route) => route?.meta?.[propName] !== undefined)
  return routeWithPrecedence?.meta?.[propName]
}


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-bridge'

Vue.use(VueI18n, { bridge: true })

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  return messages
}

const datetimeFormats = {
  'en': {
    short: {
      year: 'numeric', month: '2-digit', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'long', hour: 'numeric', minute: 'numeric'
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    readableMonth: {
      year: 'numeric', month: 'short', day: 'numeric'
    }
  },
  'fr-CA': {
    short: {
      year: "numeric", month: "2-digit", day: "2-digit"
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'long', hour: 'numeric', minute: 'numeric'
    },
  }
}

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  datetimeFormats,
  locale: 'en', // set locale
  fallbackLocale: '!', // set fallback locale
  messages: loadLocaleMessages(),
  globalInjection: true
}, VueI18n)

export default i18n

// export default new VueI18n({
//   locale: 'en',
//   fallbackLocale: 'en',
//   allowComposition: true,
//   messages: loadLocaleMessages()
// })
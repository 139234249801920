<template>
    <div>
        <h2 class="has-text-centered-mobile container-border pb-5">
            <strong>{{ $t('my_diagnosis') }}</strong>
        </h2>

        <div>
            <div v-if="patientData.diagnoses?.length > 0" class="container-border mt-5 pb-5">
                <div class="mb-4">
                    <p><strong>{{ $t('submitted_by') }} {{ patientData.diagnosesUpdatedByUserName }} on
                            {{ patientData.diagnosesUpdatedAt ? $d(new Date(patientData.diagnosesUpdatedAt), 'short',
                                'fr-CA') : "" }}</strong>
                    </p>
                </div>
                <div class=" is-flex is-flex-wrap-wrap">
                    <div v-for="diagnosis in patientData.diagnoses" :key="diagnosis" class="diagnoses-container">
                        <span>{{ diagnosis ? $t(diagnosis) : "" }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="container-border mt-5 pb-5">
                <p>
                    <strong>
                        {{ $t('no_diagnoses_currently') }}
                    </strong>
                </p>
            </div>
        </div>
        <div v-if="diagnosisData?.length > 0">
            <div class="container-border-within mt-5 pb-5" v-if="data.prolapseStage >= 0">
                <div class="mb-5">
                    <p><strong>{{ $t('prolapse_stage') }}: <span class="date-color">{{ data.prolapseStage
                    }}</span></strong>
                    </p>
                </div>
                <p>{{ $t('text_prolapse_stage_' + data.prolapseStage) }}</p>
            </div>

            <div class="container-border-within mt-5 pb-5">
                <div>
                    <p class="mb-4"><strong>{{ $t('submitted_by') }} {{ data.clinicianName }} on
                            {{ data.lastUpdatedAt ? $d(data.lastUpdatedAt, 'short', 'fr-CA') : "" }}</strong>
                    </p>
                </div>

                <div class="popq mb-3">
                    <div class="mb-4">
                        <p><strong>POP-Q (cm)</strong></p>

                        <div class="mr-1 mb-2 popq-color">

                            <div class="level m-0">
                                <div class="cell">
                                    <div class="popq-label">Aa </div>
                                    <div class="popq-value">{{ data.aaCm != null ? data.aaCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('anterior_wall') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label">Ba</div>
                                    <div class="popq-value">{{ data.baCm != null ? data.baCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('anterior_wall') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label">C</div>
                                    <div class="popq-value">{{ data.cCm != null ? data.cCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('cervix_or_cuff') }}</div>
                                </div>
                            </div>

                            <div class="level m-0">
                                <div class="cell">
                                    <div class="popq-label">gh</div>
                                    <div class="popq-value">{{ data.ghCm != null ? data.ghCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('genital_hiatus') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label">pb</div>
                                    <div class="popq-value">{{ data.pbCm != null ? data.pbCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('perineal_body') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label">tvl</div>
                                    <div class="popq-value">{{ data.tvlCm != null ? data.tvlCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('total_vaginal_length') }}</div>
                                </div>
                            </div>

                            <div class="level">
                                <div class="cell">
                                    <div class="popq-label">Ap</div>
                                    <div class="popq-value">{{ data.apCm != null ? data.apCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('posterior_wall') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label">Bp</div>
                                    <div class="popq-value">{{ data.bpCm != null ? data.bpCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('posterior_wall') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label">D</div>
                                    <div class="popq-value">{{ data.dCm != null ? data.dCm : '-' }}</div>
                                    <div class="popq-comment">{{ $t('posterior_fornix') }}</div>
                                </div>
                            </div>

                        </div>

                        <div class="mr-1 rest-color is-hidden-mobile">

                            <div class="level">
                                <div class="cell-duo">
                                    <div v-if="data.vaginalAtrophy" class="popq-vaginal-atrophy">{{
                                        $t(data.vaginalAtrophy)
                                    }}</div>
                                    <div class="popq-comment-vaginal mt-2">{{ $t('vaginal_atrophy') }}</div>
                                </div>
                                <div class="cell">
                                    <div class="popq-label mb-3"></div>
                                    <div class="popq-value pt-20">{{ data.musclesTone != null ? data.musclesTone : '-' }}</div>
                                    <div class="popq-comment">{{ $t('muscle_tone') }}</div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div>
                        <div class="p-0 ml-1">
                            <p><strong>+(at rest, cm)</strong></p>

                            <div class="mb-2 rest-color">

                                <div class="level m-0">
                                    <div class="cell">
                                        <div class="popq-label">apex</div>
                                        <div class="popq-value">{{ data.vaginalLateralApex != null ? data.vaginalLateralApex : '-' }}</div>
                                        <div class="popq-comment pl-0 pr-0">{{ $t('vaginal_lateral_width') }}</div>
                                    </div>
                                    <div class="cell">
                                        <div class="popq-label">mid</div>
                                        <div class="popq-value">{{ data.vaginalLateralMidVagina != null ? data.vaginalLateralMidVagina : '-' }}</div>
                                        <div class="popq-comment pl-0 pr-0">{{ $t('vaginal_lateral_width') }}</div>
                                    </div>
                                </div>

                                <div class="level m-0">
                                    <div class="cell">
                                        <div class="popq-label">gh</div>
                                        <div class="popq-value">{{ data.ghAtRestCm != null ? data.ghAtRestCm : '-' }}</div>
                                        <div class="popq-comment mt-4">{{ $t('at_rest') }}</div>
                                    </div>
                                    <div class="cell">
                                        <div class="popq-label">pb</div>
                                        <div class="popq-value">{{ data.pbAtRestCm != null ? data.pbAtRestCm : '-' }}</div>
                                        <div class="popq-comment mt-4">{{ $t('at_rest') }}</div>
                                    </div>
                                </div>

                                <div class="level">
                                    <div class="cell">
                                        <div class="popq-label">lateral</div>
                                        <div class="popq-value">{{ data.introitalLateralWidth != null ? data.introitalLateralWidth : '-' }}</div>
                                        <div class="popq-comment">{{ $t('introital_width') }}</div>
                                    </div>
                                    <div class="cell">
                                        <div class="popq-label-antero">antero-posterioir</div>
                                        <div class="popq-value">{{ data.introitalAnteroposteriorWidth != null ? data.introitalAnteroposteriorWidth : '-' }}</div>
                                        <div class="popq-comment">{{ $t('introital_width') }}</div>
                                    </div>
                                </div>

                            </div>

                            <div>

                                <div class="level rest-color">
                                    <div class="cell">
                                        <div class="popq-label">left</div>
                                        <div class="popq-value">{{ data.pelvicFloorStrengthOsLeft != null ? data.pelvicFloorStrengthOsLeft : '-' }}</div>
                                        <div class="popq-comment">{{ $t('pelvic_floor_strength') }}</div>
                                    </div>
                                    <div class="cell">
                                        <div class="popq-label">right</div>
                                        <div class="popq-value">{{ data.pelvicFloorStrengthOsRight != null ? data.pelvicFloorStrengthOsRight : '-' }}</div>
                                        <div class="popq-comment">{{ $t('pelvic_floor_strength') }}</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="is-hidden-tablet">
                            <div class="level mt-1">

                                <div class="cell ml-1 rest-color">
                                    <div v-if="data.vaginalAtrophy" class="popq-value-mobile">{{ $t(data.vaginalAtrophy) }}
                                    </div>
                                    <div class="popq-comment pt-0">{{ $t('vaginal_atrophy') }}</div>
                                </div>
                                <div class="cell rest-color">
                                    <div class="popq-label mb-3"></div>
                                    <div class="popq-value pt-20">{{ data.musclesTone != null ? data.musclesTone : "-"  }}</div>
                                    <div class="popq-comment">{{ $t('muscle_tone') }}</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class="mt-5">
                <h3><strong>{{ $t('history') }}</strong></h3>
                <b-table :data="diagnosisData" paginated :per-page="5" class="mt-5" pagination-order="is-centered"
                    page-input page-input-position="is-input-right" :default-sort="['encounterAt', 'desc']">
                    <b-table-column field="encounterAt" :label="$t('date')" v-slot="props"
                        header-class="break-anywhere p-4" cell-class="break-anywhere is-vcentered" sortable>
                        {{ props.row.encounterAt ? props.row.encounterAt : "" }}
                    </b-table-column>

                    <b-table-column field="prolapseStage" :label="$t('prolapse_stage')" v-slot="props"
                        header-class="break-anywhere p-4" cell-class="break-anywhere is-vcentered" sortable>
                        {{ props.row.prolapseStage >= 0 ? props.row.prolapseStage : $t('n_a') }}
                    </b-table-column>

                    <b-table-column field="Details" :label="$t('details')" v-slot="props" header-class="break-anywhere p-4"
                        cell-class="break-anywhere is-vcentered">
                        <a class="link-style" @click="viewPopq(props.row)">{{ $t('view_button')
                        }}</a>
                    </b-table-column>
                </b-table>
            </div>
            <ModalDiagnosis :displayModal="popqViewModalVisible" @close="closeViewDiagnosisModal"
                :selectedPopq="selectedPopq" :is-pgi="false" />
        </div>

        <div class="container p2" v-else>
            <div class="pt-5">
                <strong>
                    {{ $t('diagnosis_no_entry') }}.
                </strong>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { onMounted, ref } from "vue";
import { Utils } from "@/utils";
import DiagnosticsService from "@/services/resources/diagnosticsService";
import Patient from "@/models/Patient";
import patientService from "@/services/resources/patientService";
import ModalDiagnosis from "./ModalDiagnosis.vue";
import Diagnosis from "@/models/Diagnosis";


let patientData = ref(new Patient())
let selectedPopq = ref(new Diagnosis)
let popqViewModalVisible = ref(false);

let data = ref(new Diagnosis);
let diagnosisData = ref([]);
let loading = ref(false)

onMounted(async () => {
    await getPatientData();
})

function viewPopq(popq) {
    if (popq) {
        selectedPopq.value = popq;
        popqViewModalVisible.value = true;
    }
}

function closeViewDiagnosisModal() {
    popqViewModalVisible.value = false;
}

async function getPatientData() {
    let response = await DiagnosticsService.query();
    let patientResponse = await patientService.query();

    if (response.status === 200 && patientResponse.status === 200) {
        if (response.data.length === 0) {
            loading.value = false;
        } else {
            loading.value = true;
        }
        diagnosisData.value = response.data;
        data.value = response.data[0];

        patientData.value = patientResponse.data[0];
    } else {
        Utils.toastError('Error retrieving diagnosis information.')
    }
}

</script>
  
<style scoped>
.is-hidden-mobile {
    display: block;
}

.is-hidden-tablet {
    display: none;
}

@media only screen and (max-width: 820px) {
    .popq {
        flex-direction: column;
        align-items: flex-start;
    }

    .is-hidden-mobile {
        display: none;
    }

    .is-hidden-tablet {
        display: block !important;
    }

    .button.is-ghost {
        padding: 0%;
    }

    .popq-value-mobile {
        display: flex;
        width: 92px;
        height: 32px;
        flex-direction: column;
        flex-shrink: 0;
        color: var(--primary-black, #24202F);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-left: 3%;
        line-height: 15px;
        margin-top: 25px;
        flex-direction: column-reverse;
    }
}

.popq-color {
    background: var(--table-header, #DEDAE8);
}

.rest-color {
    background: #DCEFEF;
}

.link-style {
    text-decoration: underline !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding-left: 2px;
}

.diagnoses-container {
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--lilac, #DEDAE8);
    text-align: center;
    padding: 6px 30px;
    margin: 10px 40px 10px 0px;
}

.popq {
    display: flex;
    margin: 20px 0;
}

.popq-label {
    width: 70px;
    color: var(--primary-black, #24202F);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.popq-label-antero {
    width: 70px;
    color: var(--primary-black, #24202F);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    padding-top: 10px;
    padding-left: 10px;
}

.popq-value {
    display: flex;
    width: 92px;
    height: 16px;
    flex-direction: column;
    flex-shrink: 0;
    color: var(--primary-black, #24202F);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-left: 3%;
}

.popq-vaginal-atrophy {
    display: flex;
    height: 23px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--primary-black, #24202F);
    text-align: center;
    font-family: Ubuntu;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.popq-comment-vaginal {
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.popq-comment {
    width: 100px;
    height: 30px;
    font-style: normal;
    font-weight: 400;
    flex-shrink: 0;
    margin-top: 10px;
    text-align: center;
    padding: 10px 3px 0;
    line-height: 15px;
}

.level {
    margin-bottom: 0%;
    display: flex;
}

.cell-duo {
    border-radius: 0px 0px 0px 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    width: 200px;
    padding: 32px 22px 23px 22px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 13px;
    flex-shrink: 0;
    height: 116px;
}

.cell {
    width: 100px;
    height: 116px;
    flex-shrink: 0;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    margin: 0%;
}

.container-border {
    width: 100%;
    position: relative;
}

.container-border-within {
    border-bottom: 1px solid var(--stroke-stroke, #D9D9D9);
}

.container-border::before {
    content: "";
    position: absolute;
    top: 100%;
    left: -25px;
    width: calc(100% + 40px);
    height: 1px;
    bottom: -1px;
    background-color: var(--stroke-stroke, #D9D9D9);
}

.date-color {
    color: var(--primary-purple, #58458B);
}
</style>

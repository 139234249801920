import { ApiService } from "@/services/core/apiService";
import Pfiq7Section from "@/models/Pfiq7Section";

/**
 * Represents an Pfiq7 service.
 */
class Pfiq7Service extends ApiService<Pfiq7Section> {
  resourcePath: string = "Pfiq7";
}

export const pfiq7Service = new Pfiq7Service();

import { defineStore } from "pinia";
import Pessary from "@/models/Pessary";
import IntakeSubmission from "@/models/IntakeSubmission";
import PessaryHistory from "@/models/PessaryHistory";

export const useOnboardingStore = defineStore("onboarding", {
  state: () => {
    return {
      formData: new IntakeSubmission(),
    };
  },

  actions: {
    addPessary(data: Pessary) {
      this.formData.pelvicHealthSurvey.intakePessaries?.push(data);
    },

    updatePessaryByIndex(data: Pessary, index) {
        if (this.formData.pelvicHealthSurvey.intakePessaries && this.formData.pelvicHealthSurvey.intakePessaries[index]) {
          this.formData.pelvicHealthSurvey.intakePessaries.splice(index, 1, data);
        }
    },

    getNonReactiveCopyOfPessaryByIndex(indexOfPessary: number): Pessary {
      if (
        this.formData.pelvicHealthSurvey.intakePessaries &&
        this.formData.pelvicHealthSurvey.intakePessaries[indexOfPessary]
      ) {
        const nonReactiveStorePessaryCopy = JSON.parse(
          JSON.stringify(this.formData.pelvicHealthSurvey.intakePessaries[indexOfPessary])
        );
        if (nonReactiveStorePessaryCopy.fittedAt) {
          nonReactiveStorePessaryCopy.fittedAt = new Date(
            nonReactiveStorePessaryCopy.fittedAt
          );
        }
        /*
          The line below ensures that the caller receives a pessary with complete pessary history with all properties intact(even if some values are undefined). This 
          pre-caution is necessary due to backend ignoring null values when serializing and may return PessaryHistory
          with missing properties. This causes issues with vue reactivity, because vue cannot detect property addition
          or deletion - https://v2.vuejs.org/v2/guide/reactivity.html#For-Objects
        */
        return {...nonReactiveStorePessaryCopy, pessaryHistory: {...new PessaryHistory(), ...nonReactiveStorePessaryCopy.pessaryHistory}}
      }
      return new Pessary();
    },
    
    cleanIntakepelvicHealthSurveyPreSubmission() {
      // if user is not exercising, pessary history should not have any exercise related questions/answers
      if (
        this.formData.pelvicHealthSurvey.oftenExercise === "enum_no_exercise" &&
        this.formData.pelvicHealthSurvey.intakePessaries?.length
      ) {
        for (const pessary of this.formData.pelvicHealthSurvey.intakePessaries) {
          pessary.pessaryHistory.issuesExercise = undefined;
          pessary.pessaryHistory.issuesExerciseOther = undefined;
        }
      }
      // if user indicates no past or current pessary use, the submission should not have any pessary
      // related data
      if (
        !this.formData.pelvicHealthSurvey.currentlyWearingPessary &&
        this.formData.pelvicHealthSurvey.intakePessaries?.length
      ) {
        this.formData.pelvicHealthSurvey.intakePessaries = new Array();
      }

      if (this.formData.pelvicHealthSurvey.intakePessaries?.length) {
        // JS date function month is 0 indexed, so we add plus 1 when
        // submitting data
        for (const pessary of this.formData.pelvicHealthSurvey.intakePessaries) {
          if (pessary.monthStarted || pessary.monthStarted === 0) {
            pessary.monthStarted++;
          }
          if (pessary.monthStopped || pessary.monthStopped === 0) {
            pessary.monthStopped++;
          }
          if (!pessary.pessaryHistory.issuesDailyActivities || !pessary.pessaryHistory.issuesDailyActivities.includes('issues_other')) {
            pessary.pessaryHistory.issuesDailyActivitiesOther = undefined;
          }
          if (!pessary.pessaryHistory.issuesExercise || !pessary.pessaryHistory.issuesExercise.includes('issues_other')) {
            pessary.pessaryHistory.issuesExerciseOther = undefined;
          }
          if (!pessary.pessaryHistory.reasonDiscontinue || !pessary.pessaryHistory.reasonDiscontinue.includes('discontinue_other_reason')) {
            pessary.pessaryHistory.otherReasonDiscontinue = undefined;
          }
          if (pessary.pessaryHistory.selfManaged == false) {
            pessary.pessaryHistory.issuesSelfCare = undefined;
          }
        }
      }
    },
    cleanIntakepelvicHealthSurveyPostFetch() {
      // JS date function month is 0 indexed, so we subtract 1 from month when 
      // fetching date from backend.
      if (this.formData.pelvicHealthSurvey.intakePessaries?.length) {
        for (const pessary of this.formData.pelvicHealthSurvey.intakePessaries) {
          if (pessary.monthStarted) {
            pessary.monthStarted--;
          }
          if (pessary.monthStopped) {
            pessary.monthStopped--;
          }
        }
      }
    }
  },

});
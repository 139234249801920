
export default class ApplicationUser {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  notificationsEmail?: boolean;
  notificationsSms?: boolean;
  updatedPhoneAt?: Date;
  updatedEmailAt?: Date;
  updatedPasswordAt?: Date;
  language?: string;
  token?: string;
  termsAcceptedAt?: Date;
  emailVerifiedAt?: Date;
  privacyAcceptedAt?: Date;
}
import axios, { AxiosInstance } from "axios";
import i18n from "../../i18n.js";
import { Utils } from "@/utils";

const axiosSingleton = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
});

abstract class AxiosService {
  protected readonly axios: AxiosInstance = axiosSingleton;
  protected abstract readonly rootPath: string;

  protected passthroughErrorHandler(error) {
    return error.response;
  }

  protected genericErrorHandler(error) {
      if (error.response.status == 400) {
        Utils.toastError(i18n.global.t('error_message_400'));
      } else if (error.response.status == 500) {
        Utils.toastError(i18n.global.t('error_message_500'));
      }

    // Ensures our svc. methods always return the response object regardless
    // of whether the request returned error HTTP status code...
    return error.response;
  }
}

export default AxiosService;

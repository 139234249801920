export default class PrescribedPessary {
  id?: number;
  patientId?: number;
  serialNumber?: string;
  pessaryType?: string;
  manufacturerName?: string;
  currentlyWearing?: boolean;
  size?: number;
  displaySize?: string;
  dimensions?: string;
  productCode?: string
  discontinuedAt?: string;
  fittedAt?: string;
  createdAt?: Date;
}
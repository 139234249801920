export default class DemographicSubmission {
  id?: number;
  height?: number;
  weight?: number;
  unit: string = "enum_metric";
  hasChronicCough?: boolean;
  hasChronicPain?: boolean;
  vaginalDeliveries?: number;
  vacuumDeliveries?: number;
  forcepsDeliveries?: number;
  cSectionDeliveries?: number;
  surgeries?: string[];
  menopausalStatus?: string;
  isEmailNotification: boolean = false;
  isSmsNotification: boolean = false;
  preferredName?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  updatedByUser?: string;
  updatedAt?: string;
  isCompletedRegistration: boolean = false;
  completedRegistrationAt?: Date;
}

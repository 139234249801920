export default class Patient {
    id?: number;
    preferredName?: string;
    birthDate?: Date;
    healthCard?: string;
    diagnoses?: Array<string>;
    disposition?: string;
    clinicianName?: string;
    diagnosesUpdatedByUserName?: string;
    diagnosesUpdatedAt?: string;
    notificationsSms?: boolean;
    notificationsEmail?: boolean;

    // TODO : Add other properties as needed to support profile workflow
  }
  
  
import { butterCMS } from "./core/ButterCMS";

/**
 * Represents the Butter CMS service
 */
class ButterService {

  protected butterInstance: any = butterCMS;

  public async getCollection(collectionSlug: string, params: object): Promise<any> {
    const response = await this.butterInstance.content.retrieve([collectionSlug], params)
    return response.data.data
  }

  public async getPages(pageSlug: string, params: object): Promise<any> {
    params["fields.patient_content"] = true
    const response = await this.butterInstance.page.list([pageSlug], params)
    return response.data.data
  }

  public async getArticle(articleSlug: string, params: object): Promise<any> {
    const response = await this.butterInstance.page.retrieve('*', articleSlug, params)
    return response.data.data
  }
}

export default new ButterService();

import Pessary from "./Pessary";
import SectionBase from "./SectionBase";

export default class PelvicHealthSurvey extends SectionBase{
  id?: number;
  patientSectionId?: number;
  sectionReplyId? : number;
  currentlyWearingPessary?: boolean;
  patientFormId!: number;
  sexuallyActive?: string;
  sexualIntercourse?: boolean;
  dailyActivities?: string;
  oftenExercise?: string;
  typeExercise?: string;
  vaginalEstrogen?: boolean;
  vaginalLubricant?: boolean;
  frequencyEstrogenLubricant?: string;
  prolapseCondition?: number;
  incontinenceCondition?: number;
  top3Features?: string[] = [];
  otherFeatures?: string;
  intakePessaries?: Pessary[] = new Array();
  clinicianAddedPessaries?: Pessary[] = new Array();
  isCompleted: boolean = false;
  completedAt? : Date;
  saveRequest?: boolean = false;
  currentStep?: number;
}

import { render, staticRenderFns } from "./ModalAddPessary.vue?vue&type=template&id=dc1e41d6&scoped=true&"
import script from "./ModalAddPessary.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ModalAddPessary.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ModalAddPessary.vue?vue&type=style&index=0&id=dc1e41d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc1e41d6",
  null
  
)

export default component.exports
import { ApiService } from "@/services/core/apiService";
import IntroductionSubmission from "@/models/IntroductionSubmission";

/**
 * Represents an Introduction service.
 */
class IntroductionService extends ApiService<IntroductionSubmission> {
  resourcePath: string = "Introduction";
}

export const introductionService = new IntroductionService();
import PrescribedPessaryHistory from "./PrescribedPessaryHistory"
import SectionBase from "./SectionBase";

export default class FollowUp extends SectionBase {
  id?: number;
  patientFormId?: number;
  vaginalEstrogen?: boolean;
  vaginalLubricant?: boolean;
  frequencyEstrogenLubricant?: string;
  oftenExercise?: string;
  typeExercise?: string;
  completedAt?: Date;
  sectionCompleted?: boolean;
  completedBy?: string;
  prescribedPessaryHistories: PrescribedPessaryHistory[] = new Array();
}

<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center">
        <!-- Question 6 required-->
        <div>
            <p class="my-2 question-style">
                {{ $t(uiData[0].localeKey) }}
                <span class="has-text-danger">*</span>
            </p>
            <b-field>
                <b-radio v-model="onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen" :native-value="true"
                    :class="{ 'error-radio': vaginalEstrogenMessage }" :disabled="props.readOnly"
                    @input="answerSelectionUpdated">{{
                        $t(uiData[0].options.at(0).localeKey)
                    }}</b-radio>
            </b-field>
            <b-field>
                <b-radio v-model="onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen" :native-value="false"
                    :class="{ 'error-radio': vaginalEstrogenMessage }" :disabled="props.readOnly"
                    @input="answerSelectionUpdated">{{
                        $t(uiData[0].options.at(1).localeKey)
                    }}</b-radio>
            </b-field>
            <p class="error-message" v-if="vaginalEstrogenMessage && !props.readOnly"><i
                    class="mdi mdi-alert-circle-outline"></i> {{ vaginalEstrogenMessage }}</p>
        </div>
        <!-- Question 7 required-->
        <div>
            <p class="mt-4 mb-2 question-style">
                {{ $t(uiData[1].localeKey) }}
                <span class="has-text-danger">*</span>
            </p>
            <b-field>
                <b-radio v-model="onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant" :native-value="true"
                    :class="{ 'error-radio': vaginalLubricantMessage }" :disabled="props.readOnly"
                    @input="answerSelectionUpdated">{{
                        $t(uiData[1].options.at(0).localeKey)
                    }}</b-radio>
            </b-field>
            <b-field>
                <b-radio v-model="onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant" :native-value="false"
                    :class="{ 'error-radio': vaginalLubricantMessage }" :disabled="props.readOnly"
                    @input="answerSelectionUpdated">{{
                        $t(uiData[1].options.at(1).localeKey)
                    }}</b-radio>
            </b-field>
            <p class="error-message" v-if="vaginalLubricantMessage && !props.readOnly"><i
                    class="mdi mdi-alert-circle-outline"></i> {{ vaginalLubricantMessage }}</p>
        </div>
        <!-- Question 8 required-->
        <div v-if="useAtLeastOne" class="mt-4">
            <DropdownQuestion :question="$t(uiData[2].localeKey)" :options="uiData[2].options"
                :logicalId="uiData[2].logicalId" :readOnly="props.readOnly"
                :has-error="frequencyEstrogenLubricantMessage?.length > 0" :position="topRight">
            </DropdownQuestion>
            <p class="error-message" v-if="frequencyEstrogenLubricantMessage && !props.readOnly"><i
                    class="mdi mdi-alert-circle-outline"></i> {{ frequencyEstrogenLubricantMessage }}</p>
        </div>
    </div>
</template>
    
<script setup="lang">
import { ref, computed, watch } from "vue";
import vaginalHealth from "@/data/vaginalhealth.json";
import DropdownQuestion from "./DropdownQuestion.vue";
import { useOnboardingStore } from "@/store/onboardingStore";
import { useI18n } from 'vue-i18n-bridge'

let uiData = ref(vaginalHealth);
const { t } = useI18n();
const onboardingStore = useOnboardingStore();
let vaginalEstrogenMessage = ref(null);
let vaginalLubricantMessage = ref(null);
let frequencyEstrogenLubricantMessage = ref(null);
let topRight = ref("is-top-right");

const props = defineProps({
    readOnly: Boolean
});
// computed property
let useAtLeastOne = computed(() => {
    return onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen === true || onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant === true;
});

function answerSelectionUpdated() {
    onboardingStore.formData.pelvicHealthSurvey.requiresSave = true;
}

watch(useAtLeastOne, () => {
    if (useAtLeastOne.value === false) {
        onboardingStore.formData.pelvicHealthSurvey.frequencyEstrogenLubricant = undefined;
        frequencyEstrogenLubricantMessage.value = undefined;
    }
});

watch(() => onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen, () => {
    if (onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen != undefined) {
        vaginalEstrogenMessage.value = ""
    }
})

watch(() => onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant, () => {
    if (onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant != undefined) {
        vaginalLubricantMessage.value = ""
    }
})

watch(() => onboardingStore.formData.pelvicHealthSurvey.frequencyEstrogenLubricant, () => {
    if (onboardingStore.formData.pelvicHealthSurvey.frequencyEstrogenLubricant != undefined) {
        frequencyEstrogenLubricantMessage.value = ""
    }
})

function isVaginalHealthValid() {
    if (onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen === undefined) {
        vaginalEstrogenMessage.value = t("required_question")
    }
    if (onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant === undefined) {
        vaginalLubricantMessage.value = t("required_question")
    }

    if (useAtLeastOne.value) {
        if (onboardingStore.formData.pelvicHealthSurvey.frequencyEstrogenLubricant === undefined) {
            frequencyEstrogenLubricantMessage.value = t("required_question")
        }
    }

    // at least one question has been answered yes and both questions are answered
    if (onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen !== undefined && onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant !== undefined) {
        if (useAtLeastOne.value) {
            if (onboardingStore.formData.pelvicHealthSurvey.frequencyEstrogenLubricant !== undefined) {
                return true
            }
        }
    }

    // both questions have been answered no
    if (onboardingStore.formData.pelvicHealthSurvey.vaginalLubricant === false && onboardingStore.formData.pelvicHealthSurvey.vaginalEstrogen === false) {
        return true
    }

    return false
}

defineExpose({ isVaginalHealthValid })
</script>
<style scoped>
.question-style {
    color: var(--primary-black, #24202F);
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.error-message {
    color: var(--alert-error, #D32F2F);
    font-size: 12px;
    font-weight: 400;
}
</style>
    
export class Token {
    
    value!: string

    constructor(value:string){
        this.value = value;
    }
}



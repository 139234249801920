import { defineStore } from "pinia";
import FollowUpSubmission from "@/models/FollowUpSubmission";
import PrescribedPessaryHistory from "@/models/PrescribedPessaryHistory";

export const useFollowUpStore = defineStore("followup", {
  state: () => {
    return {
      formData: new FollowUpSubmission(),
      isReadOnly: false,
      askExercise: false,
      currentPessary: new PrescribedPessaryHistory()
    };
  }
});

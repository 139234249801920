/**
 * Set of shared helper methods for display, routing and sorting of form lists
 * rendered via Buefy table component
 * @author Daria Ostroushko
 */


// Return route path based on retrieved form Id and name
export function formRedirect(formId: Number, formName: string) {
  switch (formName) {
    case "forms_intake_assessment":
      return `/forms/intake-assessment/intake-home?id=${formId}`;
    case "forms_symptoms_and_impact_assessment":
      return `/forms/symptom-and-impact?id=${formId}`;
    case "forms_fitting_follow_up_survey":
      return `/forms/fitting-follow-up?id=${formId}`;
    case "forms_follow_up_survey":
      return `/forms/follow-up?id=${formId}`;
    case "report_issue":
      return `/forms/report-issue`;
    default:
      return "/forms";
  }
}

// Return shared css class name based on disposition 
export function dispositionColour(disposition: string) {
  switch (disposition) {
    case "disposition_continuing_care":
      return "cont-care";
    case "disposition_intake":
      return "intake";
    case "disposition_fitting":
      return "fitting";
    case "disposition_follow_up":
      return "follow-up";
    case "disposition_archived":
      return "cont-care";
    default:
      return "default";
  }
}

// Custom sort function supplied to buefy table for assigned date
export function sortByAssignedDate(a, b, isAsc: boolean) {
  if (isAsc) {
    return new Date(b.dateAssigned).getTime() - new Date(a.dateAssigned).getTime();
  } else {
    return new Date(a.dateAssigned).getTime() - new Date(b.dateAssigned).getTime();
  }
}

/**
 * Vertically aligns buefy table column content by adding to td as native attribute
 * usage example- :td-attrs=columnTdAttrs
 * @author Zhiping Yu
 */
export function columnTdAttrs() {
  return {
    style: {
      verticalAlign: 'middle'
    }
  }
}

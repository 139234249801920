import { render, staticRenderFns } from "./EditChildbirth.vue?vue&type=template&id=46a2f506&scoped=true&"
import script from "./EditChildbirth.vue?vue&type=script&setup=true&lang=ts&"
export * from "./EditChildbirth.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./EditChildbirth.vue?vue&type=style&index=0&id=46a2f506&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a2f506",
  null
  
)

export default component.exports
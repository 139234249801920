export default class Diagnosis {
    clinicianName?: string;
    lastUpdatedAt?: Date;
    prolapseStage?: number;
    aaCm?: number;
    baCm?: number;
    cCm?: number;
    ghCm?: number;
    pbCm?: number;
    tvlCm?: number;
    apCm?: number;
    bpCm?: number;
    dCm?: number;
    vaginalAtrophy?: string;
    musclesTone?: number;
    vaginalLateralApex?: number;
    vaginalLateralMidVagina?: number;
    ghAtRestCm?: number;
    pbAtRestCm?: number;
    introitalLateralWidth?: number;
    introitalAnteroposteriorWidth?: number;
    pelvicFloorStrengthOsLeft?: number;
    pelvicFloorStrengthOsRight?: number;

  }
  
  
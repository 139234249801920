import { RouteConfig } from "vue-router";

import NotFound from "@/components/shared/NotFound.vue";

const routes: Array<RouteConfig> = [
  {
    name: "Not Found",
    path: '*',
    component: NotFound,
    meta: {
      showNavBar: false,
      titleLocaleKey: "not_found"
    },
  },
];

export default routes;

<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center">
        <div v-for="data in displayingData" :key="data?.dates">
            <div class="columns is-mobile container-style is-multiline mb-5">
                <div class="column is-5 is-7-mobile">
                    <label class="label-style">{{ $t('intake_pessary_dates') }}</label>
                </div>
                <div class="column is-7 is-5-mobile value-style">
                    {{ data?.dates }}
                </div>
                <div class="column is-5 is-7-mobile">
                    <label class="label-style">{{ $t('pessary_outcome_manufacturer') }}</label>
                </div>
                <div class="column is-7  is-5-mobile value-style ">
                    {{ data?.pessaryManufacturer }}
                </div>
                <div class="column is-5 is-7-mobile">
                    <label class="label-style">{{ $t('pessary_outcome_type') }}</label>
                </div>
                <div class="column is-7 is-5-mobile value-style">
                    {{ data?.pessaryType }}
                </div>
                <div class="column is-5 is-7-mobile">
                    <label class="label-style">{{ $t('pessary_outcome_size') }}</label>
                </div>
                <div class="column is-7 is-5-mobile value-style">
                    {{ data?.pessarySize }}
                </div>
                <div class="column is-5 is-7-mobile">
                    <label class="label-style">{{ $t('pessary_outcome_actions') }}</label>
                </div>
                <div class="column is-7 is-5-mobile value-style">
                    <button :class="[props.readOnly ? 'button-disabled' : 'button-active', 'mr-3']"
                        @click="$emit('view-pessary', displayingData?.indexOf(data))" :disabled="props.readOnly">{{
                            $t('view_button') }}</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { computed, defineProps } from "vue";
import { Utils } from "@/utils"
import { useOnboardingStore } from "@/store/onboardingStore";
import { useI18n } from 'vue-i18n-bridge'
import pessary from "@/data/pessary.json";

const onboardingStore = useOnboardingStore();
const { t, n } = useI18n()

const props = defineProps({
    readOnly: {
        type: Boolean,
        required: true,
    }
});

let displayingData = computed(() => {

    let displaySize = "";

    const data = onboardingStore.formData.pelvicHealthSurvey.clinicianAddedPessaries?.map((item) => {
        if (item.dimensions) {
            // gynethotics
            displaySize = item.dimensions
        } else {
            // pessaries
            displaySize = Utils.getFormattedPessarySize(item) ?? t('n_a');
            
        }
        // for current pessary
        if (item.currentlyWearing === true) {
            if (item.fittedAt) {
                const displayDates = item.fittedAt
                return {
                    dates: displayDates,
                    pessaryManufacturer: t(item.manufacturerName),
                    pessaryType: item.pessaryType ? t(item.pessaryType) : '',
                    pessarySize: displaySize,
                    action: null,
                    answeredIssuesExercise: item.pessaryHistory.issuesExercise && item.pessaryHistory.issuesExercise.length > 0 ? true : false
                }
            } else {
                return {
                    dates: t('n_a'),
                    pessaryManufacturer: t(item.manufacturerName),
                    pessaryType: item.pessaryType ? t(item.pessaryType) : '',
                    pessarySize: displaySize,
                    action: null,
                    answeredIssuesExercise: item.pessaryHistory.issuesExercise && item.pessaryHistory.issuesExercise.length > 0 ? true : false
                }
            }
            // for used pessary
        } else if (item.currentlyWearing === false) {

            let displayDates;
            let monthStartedString;
            let monthStoppedString;

            if (item.yearStarted && item.yearStopped) {
                if (item.monthStarted !== undefined && item.monthStarted >= 0) {
                    monthStartedString = item.monthStarted.toString().padStart(2, '0');
                }
                if (item.monthStopped !== undefined && item.monthStopped >= 0) {
                    monthStoppedString = item.monthStopped.toString().padStart(2, '0');
                }

                displayDates = item.yearStarted + "-" + monthStartedString + " " + t('lowercaseTo') + " " + item.yearStopped + "-" + monthStoppedString;

            } else if (item.yearStarted && !item.yearStopped) {
                if (item.monthStarted !== undefined && item.monthStarted >= 0) {
                    monthStartedString = item.monthStarted.toString().padStart(2, '0');
                }

                displayDates = item.yearStarted + "-" + monthStartedString + " " + t('lowercaseTo') + " " + t('n_a')

            } else if (!item.yearStarted && item.yearStopped) {
                if (item.monthStopped !== undefined && item.monthStopped >= 0) {
                    monthStoppedString = item.monthStopped.toString().padStart(2, '0');
                }

                displayDates = t('n_a') + t('lowercaseTo') + item.yearStopped + "-" + monthStoppedString;

            } else {
                displayDates = t('n_a')
            }
            return {
                dates: displayDates,
                pessaryManufacturer: t(item.manufacturerName),
                pessaryType: item.pessaryType ? t(item.pessaryType) : '',
                pessarySize: displaySize,
                action: null,
            }
        }
    })
    return data;
});

</script>

<style scoped>
.button-active {
    color: var(--primary-purple, #58458B);
    border: none;
    padding-left: 2px;
    background: none;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;

}

.button-disabled {
    color: #aba9ae;
    border: none;
    padding-left: 2px;
    background: none;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    /* text-decoration-line: underline;  */
}

.container-style {
    width: 99%;
    margin: 0 0;
    border-top: 1px solid var(--cool-gray-30, #C1C7CD);
    border-left: 1px solid var(--cool-gray-30, #C1C7CD);
    border-right: 1px solid var(--cool-gray-30, #C1C7CD);
    ;
    background: var(--default-white, #FFF);
    /* shadow 1 */
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}

.column {
    border-bottom: 1px solid var(--cool-gray-30, #C1C7CD);
    word-wrap: break-word;
}

.label-style {
    color: var(--cool-gray-90, #21272A);
    /* Form fields/Title */
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.value-style {
    color: var(--cool-gray-60, #697077);
    /* Form fields/Description */
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.error-message {
    color: var(--alert-error, #D32F2F);
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
}

.error-border {
    border: 1px solid var(--alert-error, #D32F2F);
    margin-bottom: 5px !important;
}
</style>

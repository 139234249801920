import IntroductionSubmission from "./IntroductionSubmission";
import PelvicHealthSurvey from "./PelvicHealthSurveySubmission";
import Pfdi20Section from "./Pfdi20Section";
import Pfiq7Section from "./Pfiq7Section";
import MyHealthSubmission from "./MyHealthSubmission";

export default class IntakeSubmission {
  introductionSection: IntroductionSubmission = new IntroductionSubmission();
  myHealthSection: MyHealthSubmission = new MyHealthSubmission();
  pfiq7Section: Pfiq7Section = new Pfiq7Section();
  pfdi20Section: Pfdi20Section = new Pfdi20Section();
  pelvicHealthSurvey: PelvicHealthSurvey = new PelvicHealthSurvey();
  completedAt?: Date;
  completedBy?: String;
  isRegistrationCompleted: boolean = false;
  assignedBy?: String;
}

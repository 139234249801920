import { RouteConfig } from "vue-router";

import RegistrationView from "@/views/RegistrationView.vue";
import AcceptInvitation from "@/components/Registration/AcceptInvitation.vue";
import BadRequest from "@/components/Registration/BadRequest.vue";
import ServerError from "@/components/Registration/ServerError.vue";
import TransitionView from "@/components/Registration/TransitionView.vue";


const routes: Array<RouteConfig> = [
  {
    path: "/registration",
    component: RegistrationView,
    name: "Registration",
    meta: {
      showNavBar: false,
      titleLocaleKey: "registration"
    },
    children: [
      {
        path: "accept-invitation",
        component: AcceptInvitation,
        name: 'Accept Invitation',
      },
      {
        path: "bad-request",
        component: BadRequest,
        name: 'Bad Request',
      },
      {
        path: "server-error",
        component: ServerError,
        name: 'Server Error',
      }
    ],
  },
];

export default routes;

import { AxiosResponse } from "axios";
import AxiosService from "@/services/core/axiosService";
import ApplicationUser from "@/models/ApplicationUser";
import ContentResult from "@/models/ContentResult";
import { Token } from "@/models/Token";

/**
 * Represents an User service.
 */
class AccountService extends AxiosService {
  /**
   * Gets or set the path of the API for the service.
   */
  protected rootPath: string = "account";

  /**
   * Gets or set the path of the API for the service.
   */
  basePath: string = "account";

  /**
   * retrieves user metadata
   */
  getAsync(): Promise<AxiosResponse<ApplicationUser, any>> {
    return this.axios
      .get<ApplicationUser>(`${this.rootPath}/me`)
      .catch(this.genericErrorHandler);
  }

  /**
   * Logs a user in given they provide the correct email and password
   */
  loginAsync(data: object): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.rootPath}/login`, data)
      .catch(this.genericErrorHandler);
  }

  /**
   * Logs a user in given they provide the correct email and password
   */
  logoutAsync(): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.rootPath}/logout`, {})
      .catch(this.genericErrorHandler);
  }

  /**
   * Changes the user password to the new password provided.
   */
  changePasswordAsync(
    data: object
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.rootPath}/changePassword`, data)
      .catch(this.genericErrorHandler);
  }

  /**
  * Resets the users password to the new provided password given a valid token is present.
  */
  resetPasswordAsync(
    data: object
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/resetPassword`, data)
      .catch(this.genericErrorHandler);
  }

  /**
   * Finds user, if available, and sends a password reset link via selected contact method.
   */
  forgotPasswordAsync(
    data: object
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/forgotPassword`, data)
      .catch(this.genericErrorHandler);
  }

  /**
   * Verifies token and checks if the token is expired
   */
  validateTokenAsync(
    data: object
  ): Promise<AxiosResponse<ApplicationUser, any>> {
    return this.axios
      .post<ApplicationUser>(`${this.rootPath}/validateToken`, data)
      .catch(this.genericErrorHandler);
  }

  /**
   * Verifies user PIN Code, sets new password and logs user in
   * @param data object consisting of PIN and Password
   * @returns User with Updated Password
   */
  acceptInvitationAsync(
    data: object
  ): Promise<AxiosResponse<ApplicationUser, any>> {
    return this.axios
      .post<ApplicationUser>(`${this.rootPath}/acceptInvitation`, data)
      .catch(this.genericErrorHandler);
  }

  /**
   * Generates new PIN code for teh patient and resends the text message during accept invitation workflow
   * @param data object consisting of patient user email
   * @returns 
   */
  resendCodeAsync(data: Object): Promise<AxiosResponse<ApplicationUser, any>> {
    return this.axios
      .post<ApplicationUser>(`${this.rootPath}/resendCode`, data)
      .catch(this.genericErrorHandler);
  }

  /**
   * Sends request to change email 
   */
  changeEmailAsync(
    data: object
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/changeEmail`, data)
      .catch(this.genericErrorHandler)
  }

  /**
   * Sends request to change phone 
   */
  changePhoneAsync(
    data: object
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/changePhone`, data)
      .catch(this.genericErrorHandler)
  }

  /**
   * Confirms change of email
   */
  confirmEmailAsync(
    data: Token
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/confirmEmail`, data)
      .catch(this.genericErrorHandler)
  }

  /**
   *  confirms change of phone number
   */
  confirmPhoneAsync(
    data: Token
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/confirmPhone`, data)
      .catch(this.genericErrorHandler)
  }

  /**
   * sends request to change the users language
   * 
   * @param data the language to change to
   */
  changeLanguageAsync(data: object
  ): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/changeLanguage`, data)
      .catch(this.genericErrorHandler)
  }

  /**
   * sends request to change the users notification settings
   * 
   * @param data the notification settings to update
   */
  changeNotificationSettingsAsync(data: object): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/changeNotifications`, data)
      .catch(this.genericErrorHandler)
  }

  acceptPrivacyPolicyAsync(data: object): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/acceptPrivacyPolicy`, data)
      .catch(this.genericErrorHandler)
  }

  acceptTermsOfServiceAsync(data: object): Promise<AxiosResponse<ContentResult, any>> {
    return this.axios
      .post<ContentResult>(`${this.basePath}/acceptTermsOfService`, data)
      .catch(this.genericErrorHandler)
  }

  refreshCookieAsync(): Promise<AxiosResponse<ApplicationUser, any>> {
    return this.axios
      .get<ApplicationUser>(`${this.basePath}/refreshCookie`)
      .catch(this.genericErrorHandler);
  }

  // checkForCookieAsync(): Promise<AxiosResponse<ApplicationUser, any>> {
  //   return this.axios
  //     .get<ApplicationUser>(`${this.basePath}/checkForCookie`)
  //     .catch(this.genericErrorHandler);
  // }
}

export default new AccountService();

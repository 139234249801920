import { defineStore, StoreDefinition } from "pinia";
import LogIssueSubmission from "@/models/LogIssueSubmission";

export const useLogIssueStore: StoreDefinition = defineStore("logIssue", {
  state: () => {
    return {
      formData: new LogIssueSubmission(),
      pessaryFitAndFunction: [],
      vaginalSymptoms: [],
      urinarySymptoms: [],
      fecalSymptoms: [],
    };
  },
});

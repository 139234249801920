<template>
  <div>
    <p class="mt-2 mb-2 question-style">
      {{ props.question }}
      <span v-if="!optional" class="has-text-danger">*</span>
    </p>
    <section class="dropdown-width mb-2">
      <b-dropdown v-model="onboardingStore.formData.pelvicHealthSurvey[props.logicalId]" aria-role="list" expanded :disabled="readOnly"
        scrollable  :position="position" @change="answerSelectionUpdated">
        <template #trigger>
          <b-button icon-right="menu-down" :label="dropDownText" class="is-justify-content-space-between" :class="{'error-dropdown':hasError && !readOnly}" expanded>
          </b-button>
        </template>
        <div v-for="(item, index) in options" :key="index">
          <b-dropdown-item :value="item.logicalId" aria-role="listitem">
            <span> {{ $t(item.localeKey) }} </span>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </section>
  </div>
</template>

<script setup>
import { computed, defineProps} from "vue";
import { useI18n } from 'vue-i18n-bridge'
import { useOnboardingStore } from "@/store/onboardingStore";

const onboardingStore = useOnboardingStore();
const { t } = useI18n()
const props = defineProps({
  optional:{
    type:Boolean,
    default:false,
    required: false
  },
  question: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  logicalId: {
    type: String,
    required: true,
  },
  readOnly: {
    type: Boolean,
    required: true,
  },
  hasError:{
    type: Boolean,
    required:false,
  },
  position:{
    type:String,
    required: false
  }
});

const dropDownText = computed(() =>
  onboardingStore.formData.pelvicHealthSurvey[props.logicalId] !== undefined ? t(props.options.find(item => item.logicalId === onboardingStore.formData.pelvicHealthSurvey[props.logicalId]).localeKey) : '-'+ t('select')+'-'
);

function answerSelectionUpdated() {
  onboardingStore.formData.pelvicHealthSurvey.requiresSave = true;
}
</script>

<style scoped>
.question-style {
  color: var(--primary-black, #24202F);
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import pinia from "@/plugins/pinia";
import i18n from './i18n'
import "@/assets/styles/customBuefy.scss";
import "@/assets/styles/styles.scss";
import moment from "moment";

// import CosmServices from "@/plugins/CosmServices";

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultDateFormatter: date => {
    return moment(date).format("YYYY-MM-DD");
  },
  defaultDateParser: date => {
    return moment(date, "YYYY-MM-DD").toDate();
  }
});
Vue.use(i18n);

// Vue.use(CosmServices);

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");

import { defineStore } from "pinia";
import ApplicationUser from "@/models/ApplicationUser";
import accountService from "@/services/core/accountService";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: new ApplicationUser(),
      isLoggedIn: false,
    };
  },
  actions: {
    async setUser() {
        const response = await accountService.getAsync();
        if(response.status === 200){
          this.user = response.data;
          this.isLoggedIn = true;
          
          return true
        }
        return false
    },
  },
});

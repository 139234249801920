import { RouteConfig } from "vue-router";

import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import GenericView from "@/views/GenericView.vue";
import TermsOfServiceVue from "@/components/shared/TermsOfService.vue";
import PrivacyPolicyVue from "@/components/shared/PrivacyPolicy.vue";
import TransitionView from "@/components/Registration/TransitionView.vue";

const routes: Array<RouteConfig> = [
  {
    name: "utils",
    path: "",
    component: GenericView,
    meta: {
      showNavBar: false,
    },
    children: [
      {
        path: "/forgot-password",
        component: ForgotPassword,
        name: 'Forgot Password',
        meta: {
          titleLocaleKey: "forgot_password_header"
        }
      },
      {
        path: "/reset-password",
        component: ResetPassword,
        name: 'Reset Password',
        meta: {
          titleLocaleKey: "reset_password"
        }
      },
      {
        path: "terms-of-service",
        component: TermsOfServiceVue,
        name: 'Terms of Service',
        meta: {
          titleLocaleKey: "Terms of Service"
        }
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyVue,
        name: 'Privacy Policy',
        meta: {
          titleLocaleKey: "Privacy Policy"
        }
      },
      {
        path: "welcome",
        component: TransitionView,
        name: "Welcome Page"
      },
    ]
  },
];

export default routes;

import { RouteConfig } from "vue-router";
import HealthInformationPage from '@/components/HealthInformation/HealthInfo.vue';
import EditHealthInformationPage from '@/components/HealthInformation/EditHealthInfo.vue';
import GenericView from "@/views/GenericView.vue";


const routes: Array<RouteConfig> = [
  {
    path: "/my-health-record",
    component: GenericView,
    meta: {
      showNavBar: true,
      titleLocaleKey: "my_health_record"
    },
    children: [
      {
        name: "Health Information",
        path: "",
        component: HealthInformationPage,
      },
      {
        name: "Edit Health Information",
        path: "edit",
        component: EditHealthInformationPage,
      },
    ]
  }
]

export default routes;
import { ApiService } from "@/services/core/apiService";
import PatientForm from "@/models/PatientForm";

/**
 * Represents a FormReply Service
 */
class patientFormService extends ApiService<PatientForm> {
  resourcePath: string = "forms";
}

export default new patientFormService();

import { defineStore } from "pinia";
import DemographicSubmission from "@/models/DemographicSubmission";

export const useDemographicStore= defineStore("demographic", {
  state: () => {
    return {
      patientData: new DemographicSubmission(),
      // change tab color
      isNotificationsTabActive: false,
      isDemographicsTabActive: false,
      isChildbirthTabActive : false,
      isPelvicSurgeriesActive : false,
      isChronicIssuesActive: false,
      // check if the patient selected if they had any childbirths for submission verification 
      childbirthSelection: '',
      isCompletedRegistration:false,
    };
  },
  
});




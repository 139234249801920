import SectionBase from "./SectionBase";
export default class MyHealthSubmission extends SectionBase{
    id?: number;
    patientSectionId?: number;
    sectionReplyId?: number;
    vaginalDeliveries?: number;
    vacuumDeliveries?: number;
    forcepsDeliveries?: number;
    cSectionDeliveries?: number;
    hasChronicCough?: boolean;
    hasChronicPain?: boolean;
    surgeries?: string[] = [];
    menopausalStatus?: string;
    isCompleted: boolean = false;
    completedAt?: Date;
    currentStep?: number;
    saveRequest?: boolean;
  }
  
import Butter from "buttercms";
import { Utils } from '@/utils';

export let butterCMS;

try {
  const apiKey = String(process.env.VUE_APP_BUTTER_CMS_API_KEY);
  butterCMS = Butter(apiKey, false);
} catch (error) {
  Utils.toastError("There was an error in retrieving education content.")
}
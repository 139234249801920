import { ApiService } from "@/services/core/apiService";
import Clinic from "@/models/Clinic";

/**
 * Represents an Clinic service.
 */
class ClinicService extends ApiService<Clinic> {
  resourcePath: string = "clinic";
}

export default new ClinicService();


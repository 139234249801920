export default class PessaryHistory {
    id?: number;
    selfManaged?: boolean;
    reasonDiscontinue?: string[];
    otherReasonDiscontinue?: string;
    issuesSelfCare?: string;
    issuesDailyActivities?: string[];
    issuesDailyActivitiesOther?: string;
    issuesExercise?: string[];
    issuesExerciseOther?: string;
    currentlyWearing?: boolean;
    updatedAt?: Date;
    updatedByUserName?: string;
    createdAt?: Date;
}
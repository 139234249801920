import { ApiService } from "@/services/core/apiService";
import PGIScore from "@/models/PGIScore";


/**
 * Represents a PGI Score service.
 */
class PGIScoreService extends ApiService<PGIScore> {
  resourcePath: string = "pgiscore";
}

export default new PGIScoreService();


<template>
    <div class="pgi-container pb-4">
        <h2 class="pgi-header pb-4 has-text-centered-mobile">
            {{ $t('pgi_title') }}
        </h2>
        <div v-if="isEmpty">
            <p class="pgi-text">{{ $t('pgi_empty') }}</p>
        </div>
        <div v-else>
            <div class="pgi-h3">{{ $t('impression_of_improvement') }}</div>
            <div class="pgi-date-label">{{ $t('last_reported') }}: {{ data.updatedAt }}</div>
            <div class="pgi-description">{{ $t('pgi_description') }}</div>
            <div class="content">
                <div class="columns is-vcentered">
                    <div class="column">
                        <div v-if="data.prolapseScore != 'N/A'" class="pgi-text mb-3">{{ $t('dashboard_prolapse_condition') }}</div>
                    </div>
                    <div class="column is-narrow has-text-centered">
                        <div v-if="data.prolapseScore != 'N/A'" class="pgi-label mb-3">{{ data.prolapseScore }}</div>
                    </div>
                </div>
                <div class="columns is-vcentered">
                    <div class="column">
                        <div v-if="data.urinaryScore != 'N/A'" class="pgi-text">{{ $t('dashboard_incontinence_condition') }}</div>
                    </div>
                    <div class="column is-narrow has-text-centered">
                        <div v-if="data.urinaryScore != 'N/A'" class="pgi-label">{{ data.urinaryScore }}</div>
                    </div>
                </div>
            </div>
            
            <div>
                <a class="pgi-link-style" @click.prevent="openViewPessaryModal" v-if="data.pessary">{{ $t('pgi_view_pessary_info') }}</a>
            </div> 
        </div> 
        <ModalViewPessary :displayModal="pessaryModalActive" @close="closeViewPessaryModal" :selectedPessary="data.pessary" v-if="data.pessary" :isMobileView="mobileView" />
    </div>
</template>

<script setup>
defineProps({
    loading: Boolean,
    isEmpty: Boolean,
    data: Object,
    mobileView:Boolean,
})

import { ref } from "vue";
import ModalViewPessary from "../shared/ModalViewPessary.vue";

let pessaryModalActive = ref(false)

function closeViewPessaryModal() {
  pessaryModalActive.value = false;
}

function openViewPessaryModal() {
    pessaryModalActive.value = true
}

</script>

<style scoped>
.pgi-description {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.pgi-h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.pgi-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.empty-pgi {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.pgi-container{
    border-bottom: 1px solid var(--stroke-stroke, #D9D9D9);
}

.pgi-label {
    padding: 4px 8px;
    gap: 8px; 
    border-radius: 40px;
    border: 0.5px solid var(--primary-black, #24202F);
    background: rgba(217, 217, 217, 0.20);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.pgi-date-label {
    color:#828282;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.pgi-link-style {
    text-decoration: underline !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding-left: 2px;
}

.pgi-text {
    color: var(--secondary-text-black, #57545D); 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.content {
    margin-top: 10px;
    margin-bottom: 0 !important;
}

.columns {
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 600px) {
    .column {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
} 
</style>
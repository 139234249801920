import { render, staticRenderFns } from "./EditSurgeries.vue?vue&type=template&id=31f40542&scoped=true&"
import script from "./EditSurgeries.vue?vue&type=script&setup=true&lang=ts&"
export * from "./EditSurgeries.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./EditSurgeries.vue?vue&type=style&index=0&id=31f40542&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f40542",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./IssuesLog.vue?vue&type=template&id=5a8fb657&scoped=true&"
import script from "./IssuesLog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./IssuesLog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./IssuesLog.vue?vue&type=style&index=0&id=5a8fb657&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a8fb657",
  null
  
)

export default component.exports
import PrescribedPessary from './PrescribedPessary'

export default class PrescribedPessaryHistory {
  pessaryId?: number;
  prolapseCondition?: number;
  incontinenceCondition?: number;
  issuesSelfCare?: string;
  issuesDailyActivities: string[] = [];
  issuesDailyActivitiesOther?: string;
  issuesExercise: string[] = [];
  issuesExerciseOther?: string;
  likeMost: string[] = [];
  likeMostOther?: string;
  selfManaged?: boolean;
  reasonDiscontinue: string[] = [];
  otherReasonDiscontinue?: string;
  pessary: PrescribedPessary = new PrescribedPessary;
  pessaryUpdate: PrescribedPessary = new PrescribedPessary;
  isCompleted: boolean = false;
  currentlyWearing?: boolean;
}
import PelvicHealthSurvey from "@/models/PelvicHealthSurveySubmission";
import { ApiService } from "@/services/core/apiService";

/**
 * Represents an Intake service.
 */
class PelvicHealthSurveyService extends ApiService<PelvicHealthSurvey> {
  resourcePath: string = "PelvicHealthSurvey";
}

export default new PelvicHealthSurveyService();




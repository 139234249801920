import MyHealthSubmission from "@/models/MyHealthSubmission";
import { ApiService } from "@/services/core/apiService";

/**
 * Represents an Intake assessment service.
 */
class MyHealthSubmissionService extends ApiService<MyHealthSubmission> {
  resourcePath: string = "MyHealth";
}

export default new MyHealthSubmissionService();





import { ApiService } from "@/services/core/apiService";
import Diagnostics from "@/models/Diagnostics";


/**
 * Represents a diagnostics service.
 */
class DiagnosticsService extends ApiService<Diagnostics> {
  resourcePath: string = "diagnostics";
}

export default new DiagnosticsService();


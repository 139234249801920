import { RouteConfig } from "vue-router";
import GenericView from "@/views/GenericView.vue";
import PatientProfile from "@/components/PatientProfile.vue";
import ChangePassword from "@/components/Profile/ChangePassword.vue";
import ChangeEmail from "@/components/Profile/ChangeEmail.vue";
import ChangePhoneNumber from "@/components/Profile/ChangePhoneNumber.vue";
import ConfirmEmail from "@/components/Profile/ConfirmEmail.vue";
import ConfirmPhoneNumber from "@/components/Profile/ConfirmPhoneNumber.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/profile",
    component: GenericView,
    meta: {
      showNavBar: true,
      titleLocaleKey: "my_profile"
    },
    children: [
      {
        name: "Profile",
        path: "",
        component: PatientProfile,
      },
      {
        name: "Change Password",
        path: "change-password",
        component: ChangePassword,
      },
      {
        name: "Change Phone Number",
        path: "change-phone-Number",
        component: ChangePhoneNumber,
      },
      {
        name: "Change Email",
        path: "change-email",
        component: ChangeEmail,
      },
      {
        name: "Confirm Email",
        path: "confirm-email",
        component: ConfirmEmail,
        meta: {
          showNavBar: false,
        },
      },
      {
        name:"Confirm Phone Number",
        path:'confirm-phone-number',
        component:ConfirmPhoneNumber,
        meta: {
          showNavBar: false,
        },
      },
    ]
  }
]

export default routes;
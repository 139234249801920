import { ApiService } from "@/services/core/apiService";
import LogIssueSubmission from "@/models/LogIssueSubmission";

/**
 * Represents a Report Issue service.
 */
class LogIssueService extends ApiService<LogIssueSubmission> {
  resourcePath: string = "LogIssue";
}

export const logIssueService = new LogIssueService();

import { RouteConfig } from "vue-router";
import GenericView from "@/views/GenericView.vue";
import Pessaries from "@/components/Pessaries/Pessaries.vue";


const routes: Array<RouteConfig> = [
  {
    path: "/pessaries",
    component: GenericView,
    meta: {
      showNavBar: true,
      titleLocaleKey: "pessaries"
    },
    children: [
      {
        name: "pessaries",
        path: "",
        component: Pessaries,
      }
    ],
  },
];


export default routes;

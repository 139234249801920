import { ApiService } from "@/services/core/apiService";
import Pfdi20Section from "@/models/Pfdi20Section";

/**
 * Represents a PFDI-20 service.
 */
class Pfdi20Service extends ApiService<Pfdi20Section> {
  resourcePath: string = "pfdi20";
}

export default new Pfdi20Service();

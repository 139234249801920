import { RouteConfig } from "vue-router";
import Home from "@/components/Home.vue";
import GenericView from "@/views/GenericView.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: GenericView,
    meta: {
      showNavBar: false,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
    ]
  },
];

export default routes;

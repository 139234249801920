import Pessary from "./Pessary";

export default class LogIssueSubmission {
  patientId?: number;
  relatedToPessary?: boolean;
  deviceReportingFor?: number;
  pessary?: Pessary
  symptomsExperienced?: string[] = [];
  activityDuringExperience?: string[] = [];
  otherActivityDuringExperience?: string;
  otherIssuesExperienced?: string;
  status: string = "enum_opened"
  createdAt?: Date;
}
import { RouteConfig } from "vue-router";
import Education from "@/components/Education.vue";
import EducationCategory from "@/components/Education/EducationCategory.vue";
import GenericView from "@/views/GenericView.vue";
import EducationArticle from "@/components/Education/EducationArticle.vue";
import FAQ from "@/components/Education/FAQ.vue";
import Resources from "@/components/Education/Resources.vue"
import Glossary from "@/components/Education/Glossary.vue"

const routes: Array<RouteConfig> = [
  {
    path: "/education",
    component: GenericView,
    meta: {
      showNavBar: true,
      titleLocaleKey: "education"
    },
    children: [
      {
        name: "Education",
        path: "",
        component: Education,
      },
      {
        path: "faq",
        component: FAQ,
      },
      {
        path: "resources",
        component: Resources
      },
      {
        path: "glossary",
        component: Glossary
      },
      {
        path: ":category",
        component: EducationCategory,
      },
      {
        path: ":category/:slug",
        component: EducationArticle,
      },
    ],
  },
];

export default routes;

import { RouteConfig } from "vue-router";

import FormList from "@/components/FormList.vue";
import PostFormSubmission from "@/components/Forms/PostFormSubmission.vue";
import IntakeSymptoms from "@/components/Forms/IntakeAssessment/Symptoms/IntakeSymptoms.vue";
import IntakeImpactOfSymptoms from "@/components/Forms/IntakeAssessment/ImpactOfSymptoms/IntakeImpactOfSymptoms.vue";
import FormLogIssue from "@/components/Forms/FormLogIssue.vue";
import FormFollowUp from "@/components/Forms/FormFollowUp/FormFollowUp.vue";
import GenericView from "@/views/GenericView.vue";
import SymptomAndImpactForm from "@/components/Forms/SymptomAndImpactForm.vue"
import IntakeAssessmentHome from "@/components/Forms/IntakeAssessment/IntakeAssessmentHome.vue";
import IntakeAssessment from "@/components/Forms/IntakeAssessment/IntakeAssessment.vue";
import Introduction from "@/components/Forms/IntakeAssessment/Introduction/Introduction.vue";
import MyHealth from "@/components/Forms/IntakeAssessment/MyHealth/MyHealth.vue";
import PelvicHealthSurvey from "@/components/Forms/IntakeAssessment/PelvicHealthSurvey/PelvicHealthSurvey.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/forms",
    component: GenericView,
    meta: {
      showNavBar: true,
      titleLocaleKey: "forms"
    },
    children: [
      {
        name: "Forms",
        path: "",
        component: FormList,
      },
      {
        name: "Symptom-and-impact",
        path: "symptom-and-impact",
        component: SymptomAndImpactForm,
      },
      {
        name: "Post Form Submission",
        path: "post-form-submission",
        component: PostFormSubmission,
        props: true,
      },
      {
        name: "Log Issue",
        path: "log-issue",
        component: FormLogIssue,
      },
      {
        name: "Follow Up",
        path: "follow-up",
        component: FormFollowUp,
      },
      {
        name: "Fitting Follow Up",
        path: "fitting-follow-up",
        component: FormFollowUp,
      },
      {
        path: "intake-assessment",
        component: IntakeAssessment,
        meta: {
          showNavBar: false
        },
        children: [
          {
            path: "intake-home",
            component: IntakeAssessmentHome,
            name: 'Intake Home'
          },
          {
            path: "introduction",
            component: Introduction,
            name: 'Introduction'
          },
          {
            path: "my-health",
            component: MyHealth,
            name: 'My Health'
          },
          {
            path: "symptoms",
            component: IntakeSymptoms,
            name: 'Symptoms'
          },
          {
            path: "impact-of-symptoms",
            component: IntakeImpactOfSymptoms,
            name: 'Impact of Symptoms'
          },
          {
            path: "pelvic-health-survey",
            component: PelvicHealthSurvey,
            name: 'Pelvic Health Survey'
          },
        ]
      }
    ],
  },
];

export default routes;
